import { IDiscountCode, updateDiscountCode } from "@/api/settings";
import CloseIcon from "@mui/icons-material/Close";

import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { FormButton, FormContainer, FormTextFieldElement } from "@rhf-kit/mui";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";

interface Props {
    discount: IDiscountCode | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
    setSelected: (discount: IDiscountCode | undefined) => void;
    mutateDiscounts: () => void;
}

interface FormData {
    code: string;
    discount: string;
}

export const UpdateDiscountModal = ({
    discount,
    open,
    setOpen,
    mutateDiscounts,
    setSelected,
}: Props) => {
    const updateDiscount = useMutation({
        mutationFn: updateDiscountCode,
        onSuccess: () => {
            mutateDiscounts();
            enqueueSnackbar("Discount code successfully updated", {
                variant: "success",
            });
            setOpen(false);
            setSelected(undefined);
        },
        onError: (error: AxiosError<{ detail: string }>) => {
            if (
                error.response &&
                error.response.data &&
                error.response.data.detail
            ) {
                enqueueSnackbar(error.response.data.detail, {
                    variant: "error",
                });
            }
        },
    });

    if (!discount) {
        return null;
    }

    const defaultValues = {
        code: discount.code,
        discount: (discount.discount * 100).toFixed(1),
    };

    const handleSubmit = (data: FormData) => {
        updateDiscount.mutate({
            id: discount.id,
            code: data.code,
            discount: parseFloat(data.discount) / 100.0,
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>
                <Toolbar variant="dense">
                    <Typography variant="h6" fontWeight={600}>
                        Edit discount code
                    </Typography>
                    <Box flexGrow={1} />
                    <IconButton edge="end" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </DialogTitle>
            <DialogContent>
                <FormContainer
                    defaultValues={defaultValues}
                    onSuccess={handleSubmit}
                >
                    <Stack direction="column" spacing={2} my={2}>
                        <FormTextFieldElement
                            id="code"
                            name="code"
                            label="Code"
                            size="small"
                            required
                        />
                        <FormTextFieldElement
                            id="discount"
                            name="discount"
                            label="Discount"
                            type="number"
                            rules={{
                                min: {
                                    value: 1,
                                    message: "Discount must be at least 1%",
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        variant="standard"
                                    >
                                        %
                                    </InputAdornment>
                                ),
                            }}
                            size="small"
                            required
                        />
                    </Stack>
                    <FormButton variant="contained" color="primary">
                        Edit discount
                    </FormButton>
                </FormContainer>
            </DialogContent>
        </Dialog>
    );
};
