import { FormEmailElement, FormButton as Button } from "@rhf-kit/mui";
import { AsyncState } from "@react-hookz/web";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";
import { UserType } from "@/api/profile.ts";

interface IProps {
    state: AsyncState<UserType | undefined>;
}

export const ChangeEmailForm = ({ state }: IProps) => {
    const { reset } = useFormContext();

    useEffect(() => {
        if (state.status === "success" && state.result) {
            enqueueSnackbar("Email updated successfully!", {
                variant: "success",
            });
            reset({
                currentEmail: state.result.email,
            });
        }

        if (state.status === "error") {
            enqueueSnackbar("Error updating email", { variant: "error" });
        }
    }, [reset, state]);
    return (
        <>
            <FormEmailElement
                name={"currentEmail"}
                label={"Current Email"}
                margin={"normal"}
                size={"small"}
                disabled
                required
                fullWidth
                autoComplete="email"
                renderIcon={false}
            />
            <FormEmailElement
                name={"newEmail"}
                label={"New Email"}
                margin={"normal"}
                size={"small"}
                required
                fullWidth
                autoComplete="new-email"
                renderIcon={false}
            />
            <FormEmailElement
                name={"confirmNewEmail"}
                label={"Confirm New Email"}
                margin={"normal"}
                size={"small"}
                required
                rules={{
                    validate: (value, fieldValues) =>
                        value === fieldValues.newEmail || "Emails do not match",
                }}
                fullWidth
                autoComplete="new-email"
                renderIcon={false}
            />
            <Button
                variant="contained"
                color="primary"
                sx={{
                    mt: 2,
                    backgroundColor: "#40ABBA",
                    ":hover": { backgroundColor: "#2a6574" },
                }}
                loading={state.status === "loading"}
                loadingPosition={"end"}
            >
                Change Email
            </Button>
        </>
    );
};
