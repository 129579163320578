import {
    Box,
    Divider,
    FormGroup,
    Typography,
    InputAdornment,
} from "@mui/material";
import {
    FormCheckboxElement,
    FormEmailElement,
    FormPhoneNumberElement,
    FormSelectElement,
    FormTextFieldElement,
} from "@rhf-kit/mui";
import { AddressAutoComplete } from "@/components/dashboard/orders/process/AddressAutoComplete.tsx";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import { FormBox } from "@/components/dashboard/forms/FormContainers.tsx";
import { AsyncState, useAsync, useMountEffect } from "@react-hookz/web";
import { DBStoreOrder } from "@/api/store.ts";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";
import { getItemCategories, IItemCategory, IPricing } from "@/api/settings";
import {
    getItemSizePrice,
    getItemQuantityPrice,
    getItemFloorPrice,
    getAssemblyPrice,
    getMaxWeightPrice,
} from "@/utils/checkoutPricing";
import { OrderPriceBreakdown } from "./OrderPriceBreakdown";
import { ItemCategoryAutoComplete } from "./ItemCategoryAutoComplete";

interface IProps {
    state: AsyncState<unknown>;
    order?: DBStoreOrder;
    pricing?: IPricing;
}

const flattenCategories = (categories: IItemCategory[]): IItemCategory[] => {
    let flattened: IItemCategory[] = [];

    categories.forEach((category) => {
        flattened.push(category);
        if (category.children.length > 0) {
            flattened = flattened.concat(flattenCategories(category.children));
        }
    });

    return flattened;
};

export const OrderFormElements = ({ state, order, pricing }: IProps) => {
    const { setError, watch, setValue } = useFormContext();

    // watch form values
    const watchItemSize = watch("itemSize");
    const [itemSizePrice, setItemSizePrice] = useState<number | null>(null);
    const watchItemQuantity = watch("itemQuantity");
    const [itemQuantityPrice, setItemQuantityPrice] = useState<number | null>(
        null
    );
    const watchItemFloor = watch("itemFloor");
    const [itemFloorPrice, setItemFloorPrice] = useState<number | null>(null);
    const watchRequiresAssembly = watch("requiresAssembly");
    const [requiresAssemblyPrice, setRequiresAssemblyPrice] = useState<
        number | null
    >(null);
    const watchWeightLimit = watch("weightLimit");
    const [maxWeightPrice, setMaxWeightPrice] = useState<number | null>(null);
    const watchElevatorAvailable = watch("elevatorAvailable");

    const watchEstimatedValue = watch("estimatedValue");
    const handleEstimatedValueChange = (input: string) => {
        let value = input;
        // Remove non-numeric characters
        value = value.replace(/[^0-9.]/g, "");
        // Remove leading decimal point
        value = value.replace(/^\./, "");
        // Remove multiple decimal points
        value = value.replace(/\.+/g, ".");
        // Limit to two decimal places
        value = value.replace(/(\.\d{2})\d+/, "$1");
        // Update the state with the formatted value
        return value;
    };

    // options for item size select
    const itemSizeOptions = [
        {
            value: "48x36x6 - 60x48x12",
            label: "Small (48x36x6in - 60x48x12in)",
        },
        {
            value: "60x48x12 - 84x60x18",
            label: "Medium (60x48x12in - 84x60x18in)",
        },
        {
            value: "84x60x18 - 96x68x24",
            label: "Large (84x60x18in - 96x68x24in)",
        },
        {
            value: "96x68x24",
            label: "Extra Large (96x68x24in)",
        },
    ];

    // options for floor number select
    const floorNumberOptions = [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "3+" },
    ];

    //   disabled delivery window options - not used for now
    //   const deliveryWindowOptions = [
    //     { value: "7 Days", label: "7 days (default)" },
    //     { value: "5 Days", label: "5 Days (+$50.00)" },
    //     { value: "3 Days", label: "3 Days (+$75.00)" },
    //     { value: "2 Days", label: "2 Days (+$150.00)" },
    //   ];

    const [getItemCategoriesState, getItemCategoriesActions] =
        useAsync(getItemCategories);

    // item category options (set in useEffect below)
    const [itemCategoryOptions, setItemCategoryOptions] = useState<
        IItemCategory[]
    >([]);

    useMountEffect(() => {
        getItemCategoriesActions.execute();
    });

    useEffect(() => {
        if (
            getItemCategoriesState.status === "success" &&
            getItemCategoriesState.result
        ) {
            setItemCategoryOptions(getItemCategoriesState.result.items);
        }
    }, [getItemCategoriesState]);

    useEffect(() => {
        if (state.status === "error") {
            const error = state.error as AxiosError<{
                detail: string;
            }>;

            if (
                error.response &&
                error.response.data &&
                error.response.data.detail ===
                    "Apartment or suite number is missing."
            ) {
                setError("streetAddress2", {
                    type: "manual",
                    message: "Apartment or suite number is missing.",
                });
            } else {
                enqueueSnackbar(
                    error.response && error.response.data
                        ? `${error.response.data.detail}`
                        : "Failed to initiate payment.",
                    {
                        variant: "error",
                    }
                );
            }
        }
    }, [setError, state.error, state.status]);

    useEffect(() => {
        if (pricing) {
            if (watchItemSize) {
                setItemSizePrice(
                    getItemSizePrice({
                        size: watchItemSize,
                        pricing,
                    })
                );
            }
            if (watchItemQuantity) {
                setItemQuantityPrice(
                    getItemQuantityPrice({
                        pricing,
                        quantity: watchItemQuantity,
                        size: watchItemSize,
                    })
                );
            }
            if (watchItemFloor) {
                setItemFloorPrice(
                    getItemFloorPrice({
                        pricing,
                        floor: watchItemFloor,
                    })
                );
            }
            setRequiresAssemblyPrice(
                getAssemblyPrice({
                    pricing,
                    requiresAssembly: watchRequiresAssembly,
                })
            );
            setMaxWeightPrice(
                getMaxWeightPrice({
                    pricing,
                    isMaxWeight: watchWeightLimit,
                })
            );
            setValue(
                "estimatedValue",
                handleEstimatedValueChange(watchEstimatedValue)
            );
        }
    }, [
        watchItemSize,
        pricing,
        watchItemQuantity,
        watchItemFloor,
        watchRequiresAssembly,
        watchWeightLimit,
        watchEstimatedValue,
        setValue,
    ]);

    return (
        <FormBox>
            <Divider>Contact Information</Divider>
            <Box>
                {/* First Name Input */}
                <FormTextFieldElement
                    name={"firstName"}
                    label="First Name"
                    margin={"normal"}
                    fullWidth
                    required
                />

                {/* Last Name Input */}
                <FormTextFieldElement
                    name={"lastName"}
                    label={"Last name"}
                    margin={"normal"}
                    fullWidth
                    required
                />

                {/* Email Input */}
                <FormEmailElement
                    name={"email"}
                    label={"Email"}
                    margin={"normal"}
                    renderIcon={false}
                    fullWidth
                    required
                />

                {/* Phone Number Input */}
                <FormPhoneNumberElement
                    name={"phoneNumber"}
                    label={"Phone Number"}
                    margin={"normal"}
                    placeholder={"(XXX) XXX-XXXX"}
                    renderIcon={false}
                    fullWidth
                    required
                />

                <Divider>Delivery Location</Divider>
                {/* Destination Address Input */}
                <AddressAutoComplete />

                {/* Apartment Number Input */}
                <FormTextFieldElement
                    name={"streetAddress2"}
                    label={"Apartment Number (Optional)"}
                    margin={"normal"}
                    fullWidth
                />

                {/* City Input */}
                <FormTextFieldElement
                    name={"city"}
                    label={"City"}
                    margin={"normal"}
                    fullWidth
                    disabled
                    required
                />

                <Box display={"flex"}>
                    {/* State Input */}
                    <Box flex={1} mr={1}>
                        <FormTextFieldElement
                            name={"state"}
                            label={"State"}
                            margin={"normal"}
                            fullWidth
                            disabled
                            required
                        />
                    </Box>
                    {/* Zip Code Input */}
                    <Box flex={1} ml={1}>
                        <FormTextFieldElement
                            name={"zipCode"}
                            label={"Zip Code"}
                            margin={"normal"}
                            fullWidth
                            disabled
                            required
                        />
                    </Box>
                </Box>

                {/* Item Dropoff Instructions */}
                <FormTextFieldElement
                    name={"dropoffInstructions"}
                    label={"Dropoff Instructions (Optional)"}
                    margin={"normal"}
                    minRows={3}
                    multiline
                    fullWidth
                />

                <Divider>Item Information</Divider>

                {/* Item Description */}
                <ItemCategoryAutoComplete
                    itemCategories={flattenCategories(itemCategoryOptions)}
                />

                {/* Estimated Value Input */}
                <FormTextFieldElement
                    name={"estimatedValue"}
                    label={"Estimated Value of Items (USD)"}
                    margin={"normal"}
                    helperText={"For insurance reasons"}
                    fullWidth
                    required
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                />

                {/* Item Size Select */}
                <FormSelectElement
                    name={"itemSize"}
                    label={"Item Size"}
                    options={itemSizeOptions}
                    formControlProps={{ margin: "normal" }}
                    fullWidth
                    required
                />

                {/* Quantity Input */}
                <FormTextFieldElement
                    name={"itemQuantity"}
                    label={"Item Quantity"}
                    margin={"normal"}
                    type={"number"}
                    helperText={
                        "If you have multiple items in a box, each box counts as 1 item the size of the box."
                    }
                    rules={{
                        min: {
                            value: 1,
                            message: "Item Quantity must be at least 1",
                        },
                    }}
                    fullWidth
                    required
                />

                {/* Floor Number Select */}
                <FormSelectElement
                    name={"itemFloor"}
                    label={"Floor Number"}
                    options={floorNumberOptions}
                    formControlProps={{ margin: "normal" }}
                    fullWidth
                    required
                />

                {/* Delivery Window Select - Disabled */}
                {/* <FormSelect
          label="Delivery Window"
          required
          control={control}
          name={"deliveryWindow"}
          menuItems={deliveryWindowOptions}
          rules={{ required: "Delivery window is required" }}
          fullWidth
        /> */}

                <FormGroup>
                    <FormCheckboxElement
                        name={"requiresAssembly"}
                        label={"Does item require assembly?"}
                    />

                    <FormCheckboxElement
                        name={"weightLimit"}
                        label={"Does item weigh more than 100lbs?"}
                    />

                    <FormCheckboxElement
                        name={"elevatorAvailable"}
                        label={"Is there an Elevator available?"}
                    />

                    <FormCheckboxElement
                        name={"needsWrapped"}
                        label={"Would you like additional wrapping?"}
                    />
                </FormGroup>

                {/* price breakdown*/}
                {!order && watchItemQuantity > 0 && (
                    <OrderPriceBreakdown
                        watchedData={{
                            watchItemSize,
                            watchItemQuantity,
                            watchItemFloor,
                            watchRequiresAssembly,
                            watchWeightLimit,
                            watchElevatorAvailable,
                        }}
                        formData={{
                            itemSizePrice,
                            itemQuantityPrice,
                            itemFloorPrice,
                            requiresAssemblyPrice,
                            maxWeightPrice,
                        }}
                    />
                )}
                {!order && (
                    <Typography variant="caption" color="textSecondary">
                        *Distance fee calculated at checkout
                    </Typography>
                )}
            </Box>

            <Button
                variant="contained"
                color="primary"
                sx={{
                    mt: 2,
                    backgroundColor: "#40ABBA",
                    ":hover": { backgroundColor: "#2a6574" },
                }}
                loading={state.status === "loading"}
                loadingPosition={"end"}
            >
                {order ? "Update" : "Continue"}
            </Button>
        </FormBox>
    );
};
