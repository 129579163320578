import { DBStoreOrder, IPaymentIntent, IStore } from "../api/store";
import { OrderFormData } from "../components/dashboard/orders/process/OrderForm";
import { IDBCustomOrder } from "@/api/orders";
import { OrderFormData as CustomOrderFormData } from "../components/dashboard/orders/process/CustomOrderForm";
import { ICustomOrderPaymentIntent } from "@/api/orders";

export const getInitialFormData = (
    order?: DBStoreOrder,
    store?: IStore,
    state?: IPaymentIntent
): OrderFormData => {
    return {
        firstName: order
            ? order.first_name
            : state
              ? state.metadata.first_name
              : "",
        lastName: order
            ? order.last_name
            : state
              ? state.metadata.last_name
              : "",
        email: order ? order.email : state ? state.metadata.email : "",
        phoneNumber: order
            ? order.phone_number
            : state
              ? state.metadata.phone_number
              : "",
        address1: order
            ? order.delivery_address.address1
            : state
              ? state.metadata.delivery_address_address1
              : "",
        streetAddress2: order
            ? order.delivery_address.address2
            : state
              ? state.metadata.delivery_address_address2
              : "",
        city: order
            ? order.delivery_address.city
            : state
              ? state.metadata.delivery_address_city
              : "",
        state: order
            ? order.delivery_address.state
            : state
              ? state.metadata.delivery_address_state
              : "",
        zipCode: order
            ? order.delivery_address.zip_code
            : state
              ? state.metadata.delivery_address_zip_code
              : "",
        itemSize: order
            ? order.item_size
            : state
              ? state.metadata.item_size
              : "",
        deliveryWindow: order
            ? order.delivery_window
            : state
              ? state.metadata.delivery_window
              : "7 Days",
        itemFloor: order
            ? order.floor_level
            : state
              ? state.metadata.floor_level
              : 1,
        requiresAssembly: order
            ? order.assembly_required
            : state
              ? state.metadata.assembly_required
              : false,
        weightLimit: order
            ? order.max_weight
            : state
              ? state.metadata.max_weight
              : false,
        needsWrapped: order
            ? order.needs_wrapped
            : state
              ? state.metadata.needs_wrapped
              : false,
        elevatorAvailable: order
            ? order.elevator_available
            : state
              ? state.metadata.elevator_available
              : false,
        itemDescription: order
            ? order.item_description
            : state
              ? state.metadata.item_description
              : "",
        estimatedValue: order ? order.estimated_value : "",
        itemQuantity: order
            ? order.item_quantity
            : state
              ? state.metadata.item_quantity
              : 1,
        orderLocation: order
            ? order.order_location
            : store
              ? [
                    store.store_address.address1,
                    store.store_address.address2,
                    store.store_address.city,
                    store.store_address.state,
                    store.store_address.zip_code,
                ]
                    .filter(Boolean)
                    .join(", ")
              : "",
        dropoffInstructions: order
            ? order.dropoff_instructions
            : state
              ? state.metadata.dropoff_instructions
              : "",
        referral: order ? order.referral : state ? state.metadata.referral : "",
    };
};

export const getCustomInitialFormData = (
    order?: IDBCustomOrder,
    state?: ICustomOrderPaymentIntent
): CustomOrderFormData => {
    return {
        firstName: order
            ? order.first_name
            : state
              ? state.metadata.first_name
              : "",
        lastName: order
            ? order.last_name
            : state
              ? state.metadata.last_name
              : "",
        email: order ? order.email : state ? state.metadata.email : "",
        phoneNumber: order
            ? order.phone_number
            : state
              ? state.metadata.phone_number
              : "",
        pickup_streetAddress1: order
            ? order.pickup_address.address1
            : state
              ? state.metadata.pickup_address_address1
              : "",
        pickup_streetAddress2: order
            ? order.pickup_address.address2
            : state
              ? state.metadata.pickup_address_address2
              : "",
        pickup_city: order
            ? order.pickup_address.city
            : state
              ? state.metadata.pickup_address_city
              : "",
        addReferral: order?.referral
            ? order.referral
            : state
              ? state.metadata.referral
                  ? state.metadata.referral
                  : ""
              : "",
        pickup_state: order
            ? order.pickup_address.state
            : state
              ? state.metadata.pickup_address_state
              : "",
        pickup_zipCode: order
            ? order.pickup_address.zip_code
            : state
              ? state.metadata.pickup_address_zip_code
              : "",
        delivery_streetAddress1: order
            ? order.delivery_address.address1
            : state
              ? state.metadata.delivery_address_address1
              : "",
        delivery_streetAddress2: order
            ? order.delivery_address.address2
            : state
              ? state.metadata.delivery_address_address2
              : "",
        delivery_city: order
            ? order.delivery_address.city
            : state
              ? state.metadata.delivery_address_city
              : "",
        delivery_state: order
            ? order.delivery_address.state
            : state
              ? state.metadata.delivery_address_state
              : "",
        delivery_zipCode: order
            ? order.delivery_address.zip_code
            : state
              ? state.metadata.delivery_address_zip_code
              : "",
        itemSize: order
            ? order.item_size
            : state
              ? state.metadata.item_size
              : "",
        deliveryWindow: order
            ? order.delivery_window
            : state
              ? state.metadata.delivery_window
              : "7 Days",
        itemFloor: order
            ? order.floor_level
            : state
              ? state.metadata.floor_level
              : 1,
        requiresAssembly: order
            ? order.assembly_required
            : state
              ? state.metadata.assembly_required
              : false,
        weightLimit: order
            ? order.max_weight
            : state
              ? state.metadata.max_weight
              : false,
        needsWrapped: order
            ? order.needs_wrapped
            : state
              ? state.metadata.needs_wrapped
              : false,
        elevatorAvailable: order
            ? order.elevator_available
            : state
              ? state.metadata.elevator_available
              : false,
        itemDescription: order
            ? order.item_description
            : state
              ? state.metadata.item_description
              : "",
        estimatedValue: order ? order.estimated_value : "",
        itemQuantity: order
            ? order.item_quantity
            : state
              ? state.metadata.item_quantity
              : 1,
        dropoffInstructions: order
            ? order.dropoff_instructions
            : state
              ? state.metadata.dropoff_instructions
              : "",
    };
};
