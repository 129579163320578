import { IOrderStatus } from "../api/orders";
import { DBStoreOrder } from "../api/store";
import { IDriverOrder } from "../api/profile";

export const getOrderStatus = (
    order: DBStoreOrder | IOrderStatus | IDriverOrder
) => {
    let status: "pending" | "delivered" | "picked_up" | "accepted" = "pending";
    if (order.delivered) {
        status = "delivered";
    } else if (order.picked_up) {
        status = "picked_up";
    } else if (order.accepted) {
        status = "accepted";
    }
    return status;
};
