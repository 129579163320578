import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import { Box, Card, Link, Stack, Typography } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { DBStoreOrder, IStore, getStore } from "@/api/store";
import { OrderStoreInfoSkeleton } from "../skeletons";
import { displayStoreHours } from "@/utils";

interface IOrderStoreInfoCardProps {
    order: DBStoreOrder;
}

export const OrderStoreInfoCard = ({ order }: IOrderStoreInfoCardProps) => {
    const [getStoreState, getStoreActions] = useAsync(getStore);
    const [store, setStore] = useState<IStore>();

    useMountEffect(() => {
        getStoreActions.execute(order.store_id);
    });

    useEffect(() => {
        if (getStoreState.status === "success" && getStoreState.result) {
            setStore(getStoreState.result);
        }
    }, [getStoreState]);

    if (getStoreState.status === "loading") {
        return <OrderStoreInfoSkeleton />;
    }

    if (store) {
        const storeAddressString = `${store.store_address.address1}, ${store.store_address.city}, ${store.store_address.state} ${store.store_address.zip_code}`;

        const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            storeAddressString
        )}`;

        return (
            <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
                <Box mb={2}>
                    <Stack direction={"row"} alignItems={"center"} mb={1}>
                        <StoreRoundedIcon
                            sx={{ marginRight: 1 }}
                            color="inherit"
                        />
                        <Typography
                            variant="body2"
                            fontWeight={600}
                            textAlign={"left"}
                        >
                            Store
                        </Typography>
                    </Stack>
                    <Typography
                        variant="body2"
                        textAlign={"left"}
                        textTransform={"capitalize"}
                    >
                        {store.name}
                    </Typography>
                </Box>

                <Box mb={2}>
                    <Typography
                        mb={1}
                        variant="body2"
                        fontWeight={600}
                        textAlign={"left"}
                    >
                        Store Pickup Hours
                    </Typography>
                    <Typography variant="body2" textAlign={"left"}></Typography>
                    {displayStoreHours(store.store_hours).map(
                        (hours, index) => (
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                key={`${hours.day}-${index}`}
                            >
                                <Typography
                                    variant={"body2"}
                                    fontWeight={500}
                                    textTransform={"capitalize"}
                                >
                                    {hours.day}
                                </Typography>
                                <Typography variant={"body2"}>
                                    {hours.openTime}{" "}
                                    {hours.closeTime !== "" ? "-" : ""}{" "}
                                    {hours.closeTime}
                                </Typography>
                            </Stack>
                        )
                    )}
                </Box>

                <Box mb={2}>
                    <Typography
                        mb={1}
                        variant="body2"
                        fontWeight={600}
                        textAlign={"left"}
                    >
                        Store Address
                    </Typography>
                    <Typography variant="body2" textAlign={"left"}></Typography>
                    <Typography variant="subtitle2" textAlign={"left"}>
                        {store.store_address.address1}
                    </Typography>
                    {store.store_address.address2 && (
                        <Typography variant="subtitle2" textAlign={"left"}>
                            {store.store_address.address2}
                        </Typography>
                    )}
                    <Typography variant="subtitle2" textAlign={"left"}>
                        {store.store_address.city} {store.store_address.state}{" "}
                        {store.store_address.zip_code}
                    </Typography>
                </Box>
                <Link
                    variant="body2"
                    underline="hover"
                    href={googleMapsLink}
                    target="_blank"
                >
                    View on map
                </Link>
            </Card>
        );
    }
};
