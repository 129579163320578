import DeleteIcon from "@mui/icons-material/Delete";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { IStoreUser, deleteUser } from "@/api/users";

interface IDeleteUserProps {
    user: IStoreUser;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteUser: React.FC<IDeleteUserProps> = ({
    user,
    setRefresh,
}) => {
    const [open, setOpen] = React.useState(false);
    const [deleteUserState, deleteUserActions] = useAsync(deleteUser);

    // Handle opening and closing of dialog
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Handle deletion of user
    const handleDelete = () => {
        deleteUserActions.execute(user.id);
    };

    // Handle success and error states
    useEffect(() => {
        if (deleteUserState.status === "success") {
            enqueueSnackbar(`User ${user.email} was successfully deleted.`, {
                variant: "success",
            });
            setRefresh((prev) => !prev);
            handleClose();
        }
        if (deleteUserState.status === "error") {
            enqueueSnackbar(`There was an error deleting user ${user.email}.`, {
                variant: "error",
            });
            handleClose();
        }
    }, [deleteUserState.status, setRefresh, user.email]);

    return (
        <>
            <Tooltip
                title={
                    "This user cannot be deleted because this user is the superuser."
                }
                disableHoverListener={!user.is_superuser}
            >
                <span>
                    <IconButton
                        disabled={user.is_superuser}
                        onClick={handleOpen}
                    >
                        <DeleteIcon
                            aria-disabled={user.is_superuser}
                            key="delete"
                            sx={{
                                color: user.is_superuser ? "gray" : "red",
                                width: "16px",
                                height: "16px",
                                ":hover": { cursor: "pointer" },
                            }}
                        />
                    </IconButton>
                </span>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Delete Account
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this account? If you
                        delete this account, all associated data will be lost
                        and cannot be recovered.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        color={"error"}
                        variant={"contained"}
                    >
                        {deleteUserState.status === "loading" ? (
                            <CircularProgress size={20} color={"inherit"} />
                        ) : (
                            `Delete ${user.email}`
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
