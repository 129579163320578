import InboxIcon from "@mui/icons-material/Inbox";
import { debounce, LinearProgress, Typography } from "@mui/material";
import {
    DataGrid,
    DataGridProps,
    GridColDef,
    GridSlots,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid";
import { useCallback, useEffect } from "react";

const tableStyles = {
    bgcolor: "background.paper",

    "& .MuiDataGrid-columnHeader": {
        bgcolor: "#40ABBA",
        color: "#FFFFFF",
    },

    "& .MuiDataGrid-filler": {
        bgcolor: "#40ABBA",
    },

    "& .MuiDataGrid-topContainer": {
        bgcolor: "#40ABBA",
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-checkboxInput": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-iconButtonContainer button ": {
        color: "#FFFFFF",
    },

    "& .MuiDataGrid-menuIcon button": {
        color: "#FFFFFF",
    },
    "--DataGrid-overlayHeight": "200px",

    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
        py: "8px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
        py: "15px",
    },
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
        py: "22px",
    },
};

interface IDataTableProps<TRow extends GridValidRowModel> {
    /**
     * Rows of the table.
     *
     */
    rows: TRow[];
    /**
     * Columns of the table.
     *
     */
    columns: GridColDef<TRow>[];
    /**
     * Title of the table.
     *
     */
    title?: string;
    /**
     * Whether to auto size the columns.
     * @default false
     */
    autoSize?: boolean;
}

// This is a custom overlay for when there are no rows (data) in the table
function NoRowsOverlay() {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 50,
            }}
        >
            <InboxIcon
                style={{ fontSize: 128, opacity: 0.4, color: "#40ABBA" }}
            />
            <Typography variant={"body2"}>No Data Available</Typography>
        </div>
    );
}

/**
 * Custom implementation of MUI DataGrid component for our use.
 * Custom styles for the table, and overlays for no data and loading.
 *
 */
export const DataTable = <TRow extends GridValidRowModel>({
    rows,
    columns,
    autosizeOptions,
    sx,
    autoSize = false,
    ...other
}: IDataTableProps<TRow> &
    Omit<DataGridProps, "rows" | "columns" | "autosizeOnMount">) => {
    const apiRef = useGridApiRef();

    const handleAutoSize = useCallback(() => {
        if (!autoSize || !apiRef.current) return;
        apiRef.current.autosizeColumns(autosizeOptions);
    }, [autoSize, apiRef, autosizeOptions]);

    // autosize columns on data change
    useEffect(() => {
        setTimeout(() => {
            handleAutoSize();
        }, 100);
    }, [apiRef, autoSize, autosizeOptions, rows, handleAutoSize]);

    // autosize columns on window resize
    useEffect(() => {
        const onResize = debounce(handleAutoSize, 100);

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [handleAutoSize]);

    const style = { ...tableStyles, ...sx };

    return (
        <DataGrid
            autoHeight
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            sx={style}
            autosizeOnMount={autoSize}
            autosizeOptions={autosizeOptions}
            getRowHeight={() => "auto"}
            slots={{
                noRowsOverlay: NoRowsOverlay,
                loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
            }}
            slotProps={{
                filterPanel: {
                    filterFormProps: {
                        operatorInputProps: {
                            disabled: true,
                            sx: { display: "none" },
                        },
                    },
                },
            }}
            {...other}
        />
    );
};
