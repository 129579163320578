import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    Backdrop,
    Box,
    Card,
    CircularProgress,
    Typography,
} from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ICreateStoreOrder, IPaymentIntent, createOrder } from "@/api/store";
import {
    createCustomOrder,
    ICustomOrder,
    ICustomOrderPaymentIntent,
} from "@/api/orders.ts";

interface IOrderConfirmationProps {
    paymentIntent: IPaymentIntent | ICustomOrderPaymentIntent;
}

export const ConfirmOrder = ({ paymentIntent }: IOrderConfirmationProps) => {
    const { store_id } = useParams();
    const [hasTriggeredOrder, setHasTriggeredOrder] = useState<boolean>(true);

    const [createOrderState, createOrderActions] = useAsync(createOrder);
    const [createCustomOrderState, createCustomOrderActions] =
        useAsync(createCustomOrder);

    // On mount set hasTriggeredOrder to false
    useMountEffect(() => {
        setHasTriggeredOrder(false);
    });

    // listens for hasTriggeredOrder and only executes once
    useEffect(() => {
        if (store_id && !hasTriggeredOrder) {
            const { metadata } = paymentIntent as IPaymentIntent;
            const order: ICreateStoreOrder = {
                store_id: Number(store_id),
                first_name: metadata.first_name,
                last_name: metadata.last_name,
                email: metadata.email,
                phone_number: metadata.phone_number,
                order_location: metadata.order_location,
                delivery_address: {
                    address1: metadata.delivery_address_address1,
                    address2: metadata.delivery_address_address2,
                    city: metadata.delivery_address_city,
                    state: metadata.delivery_address_state,
                    zip_code: metadata.delivery_address_zip_code,
                },
                item_size: metadata.item_size,
                item_quantity: metadata.item_quantity,
                delivery_window: metadata.delivery_window,
                floor_level: Number(metadata.floor_level),
                elevator_available: Boolean(metadata.elevator_available),
                assembly_required: Boolean(metadata.assembly_required),
                max_weight: Boolean(metadata.max_weight),
                needs_wrapped: Boolean(metadata.needs_wrapped),
                item_description: metadata.item_description,
                estimated_value: metadata.estimated_value,
                payment_intent_id: paymentIntent.id,
                dropoff_instructions: metadata.dropoff_instructions,
            };
            createOrderActions.execute(Number(store_id), order);
        }

        if (!store_id && !hasTriggeredOrder) {
            const { metadata } = paymentIntent as ICustomOrderPaymentIntent;
            const order: ICustomOrder = {
                first_name: metadata.first_name,
                last_name: metadata.last_name,
                email: metadata.email,
                phone_number: metadata.phone_number,
                delivery_address: {
                    address1: metadata.delivery_address_address1,
                    address2: metadata.delivery_address_address2,
                    city: metadata.delivery_address_city,
                    state: metadata.delivery_address_state,
                    zip_code: metadata.delivery_address_zip_code,
                },
                pickup_address: {
                    address1: metadata.pickup_address_address1,
                    address2: metadata.pickup_address_address2,
                    city: metadata.pickup_address_city,
                    state: metadata.pickup_address_state,
                    zip_code: metadata.pickup_address_zip_code,
                },
                item_size: metadata.item_size,
                item_quantity: metadata.item_quantity,
                delivery_window: metadata.delivery_window,
                floor_level: Number(metadata.floor_level),
                elevator_available: Boolean(metadata.elevator_available),
                assembly_required: Boolean(metadata.assembly_required),
                max_weight: Boolean(metadata.max_weight),
                needs_wrapped: Boolean(metadata.needs_wrapped),
                item_description: metadata.item_description,
                estimated_value: metadata.estimated_value,
                payment_intent_id: paymentIntent.id,
                referral: metadata.referral,
                dropoff_instructions: metadata.dropoff_instructions,
            };
            createCustomOrderActions.execute(order);
        }
    }, [
        hasTriggeredOrder,
        store_id,
        paymentIntent,
        createOrderActions,
        createCustomOrderActions,
    ]);

    useEffect(() => {
        if (
            createOrderState.status === "loading" ||
            createCustomOrderState.status === "loading"
        ) {
            setHasTriggeredOrder(true);
        }

        if (
            createOrderState.status === "success" ||
            createCustomOrderState.status === "success"
        ) {
            console.log("Order created successfully");
        }
    }, [createOrderState, createCustomOrderState]);

    if (
        createOrderState.status === "loading" ||
        createCustomOrderState.status === "loading"
    )
        return (
            <Backdrop
                open={
                    createOrderState.status === "loading" ||
                    createCustomOrderState.status === "loading"
                }
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );

    return (
        <>
            <Typography
                variant="h5"
                fontWeight={900}
                textAlign={"center"}
                color={"#2B3674"}
                my={2}
            >
                Order Confirmation
            </Typography>
            <Box minHeight={"100vh"} p={"1em"}>
                {/* Order Confirmed Card */}
                <Card
                    sx={{
                        maxWidth: "xl",
                        height: "80vh",
                        mx: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "2em",
                        gap: "1em",
                    }}
                >
                    <CheckCircleIcon
                        sx={{ color: "#4BB543", fontSize: "4rem" }}
                    />
                    <Typography variant="h4" fontWeight={600}>
                        Order Confirmed
                    </Typography>
                    <Box>
                        <Typography
                            textAlign={"center"}
                            variant="body1"
                            color={"#4B5563"}
                            mb={1}
                        >
                            Thank you for your purchase. Your order has been
                            confirmed.
                        </Typography>
                        <Typography
                            textAlign={"center"}
                            variant="body1"
                            color={"#4B5563"}
                        >
                            An email receipt will be sent to you shortly.
                        </Typography>
                    </Box>
                </Card>
            </Box>
        </>
    );
};
