import { Backdrop, Box, CircularProgress, Toolbar } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrder } from "@/api/orders";
import { DBStoreOrder } from "@/api/store";
import { DeleteOrderButton } from "@/components/dashboard/orders";
import { BackButton } from "@/components/ui/BackButton";
import EditOrderCard from "./components/EditOrderCard";
import EditOrderStatus from "./components/EditOrderStatus";

const StoreOrdersEditOrder = () => {
    const navigate = useNavigate();
    const { order_id } = useParams();
    const [order, setOrder] = useState<DBStoreOrder>();
    const [ordersState, ordersActions] = useAsync(getOrder);
    const isStore = location.pathname.includes("stores");

    useMountEffect(() => {
        if (order_id) {
            ordersActions.execute(order_id);
        }
    });

    useEffect(() => {
        if (ordersState.status === "success" && ordersState.result) {
            setOrder(ordersState.result);
        }
    }, [ordersState, order_id]);

    return (
        <Box
            padding={"1em"}
            bgcolor={"#F4F7FE"}
            minHeight={"100vh"}
            width={"100%"}
        >
            {/* Spacing for mobile app bar */}
            <Toolbar sx={{ display: { sm: "none" } }} />

            <Box maxWidth={"xl"} marginX={"auto"}>
                {/* loading spinner */}
                <Backdrop
                    open={ordersState.status === "loading"}
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                >
                    <CircularProgress sx={{ color: "#ffffff" }} />
                </Backdrop>
                {order && order.id && (
                    <Box maxWidth={"md"} mx={"auto"}>
                        <BackButton onClick={() => navigate(-1)} />
                        {!isStore && <EditOrderStatus order_id={order.id} />}

                        <EditOrderCard order={order} />
                        <DeleteOrderButton order={order} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default StoreOrdersEditOrder;
