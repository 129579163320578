import { getItemCategories, getPricing } from "@/api/settings";
import { Backdrop, Box, CircularProgress, Stack } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useQuery } from "@tanstack/react-query";
import { DeliveryWindowsForm } from "./DeliveryWindowsForm";
import { DiscountCodesForm } from "./DiscountCodesForm";
import { FloorLevelsForm } from "./FloorLevelsForm";
import { ItemCategoriesForm } from "./ItemCategoriesForm";
import { ItemSizesForm } from "./ItemSizesForm";
import { MiscCostsForm } from "./MiscCostsForm";

export const PricingSettings = () => {
    const [getPricingState, getPricingActions] = useAsync(getPricing);

    const {
        isPending: isCategoriesPending,
        isError: isCategoriesError,
        data: categories,
    } = useQuery({
        queryKey: ["item-categories"],
        queryFn: getItemCategories,
    });

    useMountEffect(() => {
        getPricingActions.execute();
    });

    if (getPricingState.status === "loading" || isCategoriesPending)
        return (
            <Backdrop
                open={
                    getPricingState.status === "loading" || isCategoriesPending
                }
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );

    if (
        getPricingState.status === "success" &&
        getPricingState.result &&
        !isCategoriesError &&
        categories.items
    ) {
        return (
            <Box maxWidth={"xl"} marginX={"auto"}>
                <Stack direction={{ md: "row", sm: "column" }} gap={4}>
                    <Stack spacing={4} flex={2}>
                        <ItemSizesForm
                            itemSizes={getPricingState.result.item_sizes}
                        />
                        <DeliveryWindowsForm
                            deliveryWindows={
                                getPricingState.result.delivery_windows
                            }
                        />
                        <FloorLevelsForm
                            floorLevels={getPricingState.result.floor_levels}
                        />
                    </Stack>
                    <Stack flex={1} spacing={4}>
                        <MiscCostsForm
                            miscCosts={getPricingState.result.misc_costs}
                        />
                        <ItemCategoriesForm itemCategories={categories.items} />
                        <DiscountCodesForm />
                    </Stack>
                </Stack>
            </Box>
        );
    }
};
