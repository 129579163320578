import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Chip, CircularProgress } from "@mui/material";
import * as React from "react";
import { OrderStatusMenu } from "./OrderStatusMenu";

export type OrderStatus =
    | "pending"
    | "accepted"
    | "picked_up"
    | "delivered"
    | "paid"
    | "unpaid";

interface IOrderStatusChipProps {
    orderStatus: OrderStatus;
    order_id: number;
    allowStatusUpdate?: boolean;
}

export const OrderStatusChip = ({
    orderStatus,
    order_id,
    allowStatusUpdate = false,
}: IOrderStatusChipProps) => {
    const [isLoading, setIsLoading] = React.useState(false);

    // Menu handlers for changing order status
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Map order status to label, color, and variant for the Chip component
    const statusMap: {
        [key: string]: {
            label:
                | "Pending"
                | "Accepted"
                | "Picked Up"
                | "Delivered"
                | "Paid"
                | "Unpaid";
            color:
                | "info"
                | "success"
                | "warning"
                | "error"
                | "default"
                | "primary";
            variant: "filled" | "outlined";
            value:
                | "pending"
                | "accepted"
                | "picked_up"
                | "delivered"
                | "paid"
                | "unpaid";
        };
    } = {
        pending: {
            label: "Pending",
            color: "warning",
            variant: "filled",
            value: "pending",
        },
        accepted: {
            label: "Accepted",
            color: "info",
            variant: "filled",
            value: "accepted",
        },
        picked_up: {
            label: "Picked Up",
            color: "success",
            variant: "filled",
            value: "picked_up",
        },
        delivered: {
            label: "Delivered",
            color: "default",
            variant: "filled",
            value: "delivered",
        },
        paid: {
            label: "Paid",
            color: "default",
            variant: "filled",
            value: "paid",
        },
        unpaid: {
            label: "Unpaid",
            color: "warning",
            variant: "filled",
            value: "unpaid",
        },
    };

    const status = statusMap[orderStatus];

    return (
        <>
            <Chip
                sx={{
                    borderRadius: ".5rem",
                    bgcolor: `${status.color}.light`,
                    p: "1px",
                    height: "auto",
                    "& .MuiChip-label": {
                        padding: "0px 8px",
                    },
                    "& .MuiChip-deleteIcon": {
                        marginRight: "0px",
                        fontSize: "22px",
                    },
                }}
                size="medium"
                label={status.label}
                color={status.color}
                variant={status.variant}
                onClick={
                    allowStatusUpdate
                        ? (event) => handleClick(event)
                        : undefined
                }
                onDelete={
                    allowStatusUpdate
                        ? (event) => handleClick(event)
                        : undefined
                }
                deleteIcon={
                    isLoading ? (
                        <CircularProgress
                            sx={{ p: 0.5 }}
                            size={20}
                            color="inherit"
                        />
                    ) : (
                        <ChevronRightRoundedIcon
                            sx={{
                                transform: open ? "rotate(90deg)" : "none",
                                transition: "transform 120ms ease-in-out",
                            }}
                        />
                    )
                }
            />
            <OrderStatusMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                status={status.value}
                order_id={order_id}
                setIsLoading={setIsLoading}
            />
        </>
    );
};
