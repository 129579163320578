import { checkDiscountCode, ICustomOrderPaymentIntent } from "@/api/orders.ts";
import { IPaymentIntent, IStore } from "@/api/store";
import { confirmPayment } from "@/api/stripe";
import { getPaymentBreakdown } from "@/utils";
import { calculateTotalExcludingDiscount } from "@/utils/getPaymentBreakdown";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Divider,
    InputAdornment,
    Link,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface ICheckoutFormProps {
    store?: IStore;
    paymentIntent: IPaymentIntent | ICustomOrderPaymentIntent;
}

export const CheckoutForm = ({ store, paymentIntent }: ICheckoutFormProps) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const queryClient = useQueryClient();

    // confirm stripe payment
    const [confirmPaymentState, confirmPaymentActions] =
        useAsync(confirmPayment);

    const [checkDiscountState, checkDiscountActions] =
        useAsync(checkDiscountCode);

    const { email, discount } = paymentIntent.metadata;

    const amount = (paymentIntent.amount / 100).toFixed(2);
    const noDiscountTotal = (
        calculateTotalExcludingDiscount(paymentIntent) / 100
    ).toFixed(2);
    const hasDiscount = amount !== noDiscountTotal;
    const code = discount?.split("|")[0];

    const [discountCode, setDiscountCode] = useState(code ? code : "");

    const checkDiscount = async () => {
        if (discountCode === "") {
            enqueueSnackbar({
                variant: "error",
                message: "You must provide a discount code",
            });
            return;
        }
        checkDiscountActions.execute(paymentIntent.id, discountCode);
    };

    const handleSubmit = async () => {
        if (!stripe || !elements) return;

        if (store) {
            confirmPaymentActions.execute(
                stripe,
                elements,
                paymentIntent as IPaymentIntent,
                store.id
            );
        }

        if (!store) {
            confirmPaymentActions.execute(
                stripe,
                elements,
                paymentIntent as ICustomOrderPaymentIntent
            );
        }
    };

    // handle discount code
    useEffect(() => {
        if (
            checkDiscountState.status === "success" &&
            checkDiscountState.status
        ) {
            queryClient.invalidateQueries({
                queryKey: ["checkout-payment-intent"],
            });
        }

        if (checkDiscountState.status === "error") {
            const error = checkDiscountState.error as AxiosError<{
                detail: string;
            }>;
            if (
                error.response &&
                error.response.data &&
                error.response.data.detail
            ) {
                enqueueSnackbar({
                    variant: "error",
                    message: error.response.data.detail,
                });
            }
        }
    }, [checkDiscountState, queryClient]);

    // handle confirm payment success
    useEffect(() => {
        if (
            confirmPaymentState.status === "success" &&
            confirmPaymentState.result
        ) {
            store
                ? navigate(
                      `/stores/${store.id}/shipping-form/${paymentIntent.id}/order-confirmation`
                  )
                : navigate(`/orders/${paymentIntent.id}/order-confirmation`);
        }
    }, [confirmPaymentState, navigate, paymentIntent.id, store]);

    return (
        <>
            <Card>
                <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent={"space-between"}
                >
                    <Box flex={1} m={{ md: 3, xs: 2 }}>
                        <Card
                            sx={{
                                backgroundColor: "grey.200",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {/* Payment Details Container */}
                            <Typography
                                variant="h6"
                                component="h2"
                                fontWeight={600}
                                gutterBottom
                                pt={2}
                                px={2}
                            >
                                Payment Details
                            </Typography>
                            <Divider />

                            <Box my={1} px={2}>
                                <Stack gap={0.5}>
                                    {getPaymentBreakdown(paymentIntent)
                                        .filter((item) => item.value !== null)
                                        .map(
                                            ({ label, desc, value }, index) => (
                                                <Stack
                                                    key={index}
                                                    direction="row"
                                                    mb={1}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        textAlign={"left"}
                                                        textTransform={
                                                            "capitalize"
                                                        }
                                                        flex={1}
                                                    >
                                                        {label}
                                                    </Typography>
                                                    {desc && (
                                                        <Typography
                                                            variant="caption"
                                                            textAlign={"left"}
                                                            textTransform={
                                                                "capitalize"
                                                            }
                                                            mx={1}
                                                            flex={1}
                                                        >
                                                            {desc}
                                                        </Typography>
                                                    )}

                                                    <Typography
                                                        variant="body2"
                                                        textAlign={"right"}
                                                        flex={1}
                                                    >
                                                        $
                                                        {(
                                                            Number(value) / 100
                                                        ).toFixed(2)}
                                                    </Typography>
                                                </Stack>
                                            )
                                        )}
                                </Stack>
                            </Box>
                            <Box flexGrow={1} />

                            <Box>
                                <Divider />
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    mt={2}
                                    px={2}
                                    pb={2}
                                >
                                    <Typography fontWeight={900} fontSize={18}>
                                        Total
                                    </Typography>
                                    <Box>
                                        {hasDiscount && (
                                            <Typography
                                                color={"grey"}
                                                sx={{
                                                    textDecoration:
                                                        "line-through",
                                                }}
                                            >
                                                ${noDiscountTotal}
                                            </Typography>
                                        )}
                                        <Typography>${amount}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </Box>

                    {/* Stripe inputs */}
                    <Box flex={1} m={{ md: 3, xs: 2 }}>
                        <TextField
                            sx={{ mb: 1 }}
                            fullWidth
                            size="small"
                            label="Redeem Discount Code"
                            variant="outlined"
                            disabled={hasDiscount}
                            value={discountCode}
                            onChange={(e) => {
                                setDiscountCode(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            variant="text"
                                            disabled={hasDiscount}
                                            onClick={() => checkDiscount()}
                                        >
                                            Apply
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <form id="payment-form">
                            <Typography
                                variant="body2"
                                component="h2"
                                color={"black"}
                                mb={1}
                            >
                                Email
                            </Typography>

                            <Box>
                                <input
                                    id="email"
                                    type="text"
                                    value={email}
                                    placeholder="Enter email address"
                                    disabled
                                    style={{
                                        padding: "10px",
                                        fontSize: "16px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        width: "100%",
                                        backgroundColor: "#f0f0f0",
                                        color: "#a9a9a9",
                                        marginBottom: "10px",
                                    }}
                                />

                                <PaymentElement
                                    id="payment-element"
                                    options={{ layout: "tabs" }}
                                />
                            </Box>

                            <Button
                                variant={"contained"}
                                onClick={handleSubmit}
                                disabled={!stripe || !elements}
                                id="submit"
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                {confirmPaymentState.status === "loading" ? (
                                    <CircularProgress
                                        size={25}
                                        color="inherit"
                                    />
                                ) : (
                                    "Pay now"
                                )}
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Card>
            <Box display={"flex"} justifyContent={"center"} marginTop={1}>
                <Typography
                    fontSize={16}
                    fontWeight={700}
                    color={"gray"}
                    marginRight={1}
                >
                    Have an issue? Contact Us!
                </Typography>
                <Link marginRight={1} href="mailto:support@trukem.com">
                    support@trukem.com
                </Link>
                <Typography>+1 (330) 423-5438</Typography>
            </Box>
        </>
    );
};
