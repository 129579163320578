import { updateProfile } from "@/api/profile";
import { useAuth } from "@/components/context/useAuth";
import { TermsOfServiceContainer } from "@/components/dashboard";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    List,
    ListItem,
    Stack,
    Typography,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export const DriverTermsOfService = () => {
    const { user, session } = useAuth();
    const todaysDate = new Date();
    const [accepted, setAccepted] = useState(false);

    const [updateUserState, updateUserActions] = useAsync(updateProfile);

    const handleContinue = async () => {
        if (user) {
            const updatedUser = { ...user, driver_terms_accepted: true };
            await updateUserActions.execute(updatedUser);
        }
    };

    useEffect(() => {
        if (updateUserState.status === "success") {
            updateUserActions.reset();
            session.refresh();
        }
    }, [session, updateUserActions, updateUserState]);

    if (user && user.driver_terms_accepted) {
        return <Navigate to="/" replace />;
    }
    return (
        <Box
            sx={{
                backgroundColor: "#F2F2F2",
                minHeight: "100vh",
                width: "100%",
            }}
        >
            <TermsOfServiceContainer>
                <Typography
                    variant={"h5"}
                    textAlign={"center"}
                    gutterBottom
                    p={2}
                >
                    Truk-Em Driver Terms of Service
                </Typography>
                <Box
                    color={"#313131"}
                    sx={{
                        bgcolor: "#FFFFFF",
                        maxHeight: "60vh",
                        overflowY: "auto",
                    }}
                    padding={{ xs: 2, sm: 4, md: 8 }}
                >
                    <Typography variant="h6" gutterBottom>
                        DELIVERY PARTNER TERMS & CONDITIONS
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        This Independent Contractor Agreement is made as of this{" "}
                        <strong>{todaysDate.toDateString()}</strong>, by and
                        between PollyTech, LLC, an Ohio limited liability
                        company and{" "}
                        <strong>
                            {user?.first_name} {user?.last_name}
                        </strong>
                        .
                    </Typography>
                    <Typography variant="h6" mt={4} gutterBottom>
                        Recitals
                    </Typography>
                    <List>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>A.</strong> PollyTech is an Ohio limited
                                liability company duly organized under Ohio law
                                which provides Software as a Service solutions
                                to independent delivery partners.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>B.</strong> PollyTech desires to retain
                                the services of Contractor on the terms and
                                conditions set forth in this Agreement and
                                Contractor desires to be retained by PollyTech
                                on said terms and conditions.
                            </Typography>
                        </ListItem>
                    </List>
                    <Typography variant="body2" mt={4} gutterBottom>
                        In consideration of the mutual covenants and promises
                        herein contained, Company and Contractor hereby agree as
                        follows:
                    </Typography>

                    <List>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>Term of Engagement.</strong> PollyTech
                                hereby retains Contractor beginning on{" "}
                                <strong>{todaysDate.toDateString()}</strong> and
                                continuing thereafter until terminated as
                                provided herein.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>Services.</strong> Contractor shall
                                render certain delivery, logistical, and
                                transportation services for PollyTech’s Truk-Em
                                Platform. This agreement can be modified if
                                mutually agreed to by the parties in writing.
                                Contractor further agrees to abide by any and
                                all policies, rules and regulations, as may be
                                promulgated from time to time by PollyTech.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>Compensation.</strong> In consideration
                                for the performance of Services by Contractor as
                                described herein, PollyTech shall compensate
                                Contractor 70% of the Service Rate. Contractor
                                acknowledges and agrees that, except as provided
                                in this Section 3, they shall not be entitled
                                to, and PollyTech shall not be obligated to pay,
                                any other compensation for the Services.
                                Contractor shall not be entitled to receive any
                                benefits from PollyTech.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ flexDirection: "column" }}>
                            <Typography variant="body2" gutterBottom>
                                <strong>
                                    Independent Contractor Relationship.
                                </strong>{" "}
                                Business Partner agrees to perform the Services
                                hereunder solely as an independent Business
                                Partner. The parties recognize that this
                                Agreement does not create any agency,
                                partnership, franchise, or employment
                                relationship.
                            </Typography>
                            <List>
                                <ListItem>
                                    <Typography variant="body2" gutterBottom>
                                        A. Contractor agrees to perform the
                                        Services hereunder solely as an
                                        independent contractor. The parties to
                                        this Agreement recognize that this
                                        Agreement does not create any actual or
                                        apparent agency, partnership, franchise,
                                        or employment relationship between the
                                        parties.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body2" gutterBottom>
                                        B. The Contractor shall maintain, for
                                        the duration of this business
                                        relationship, comprehensive insurance
                                        coverage that protects PollyTech against
                                        all risks associated with the services
                                        provided. This insurance shall include,
                                        but is not limited to, commercial
                                        general liability, commercial auto
                                        liability for any owned, non-owned, or
                                        hired vehicles, property damage, theft,
                                        cargo insurance, and workers'
                                        compensation. Any bodily damages, or
                                        damages done to cargo, property,
                                        vehicle, are the responsibility of
                                        Contractor, and Contractor shall have
                                        the appropriate coverage. Contractor
                                        shall notify PollyTech when the coverage
                                        PollyTech has on file lapses. Contractor
                                        shall provide PollyTech a new policy
                                        upon issuance from the insurance
                                        provider. Lapse or lack of coverage
                                        could result in Contractor being removed
                                        from the platform.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body2" gutterBottom>
                                        C. The Contractor’s Services shall be
                                        provided by Contractor using
                                        Contractor’s own resources.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body2" gutterBottom>
                                        D. PollyTech has entered into this
                                        Agreement in material part because
                                        Contractor is able to provide services
                                        to PollyTech without significant
                                        direction or supervision.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body2" gutterBottom>
                                        E. Contractor is permitted to
                                        sub-contract within their own entity,
                                        but shall not use additional
                                        sub-contractors that are not covered on
                                        the Contractor’s insurance
                                        policy/coverage.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body2" gutterBottom>
                                        F. PollyTech shall not be liable for
                                        taxes, workers’ compensation,
                                        unemployment insurance, employers'
                                        liability, employer's FICA, social
                                        security, withholding tax or other taxes
                                        or withholding for or on behalf of
                                        Contractor or any other person
                                        affiliated with or employed by
                                        Contractor in performing Services under
                                        this Agreement. All such payments shall
                                        be Contractor's sole and exclusive
                                        responsibility.
                                    </Typography>
                                </ListItem>
                            </List>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>Termination.</strong> This Agreement
                                shall commence on the Effective Date and shall
                                continue until this Agreement is terminated by
                                either party upon fifteen (15) days prior
                                written notice. Further, this Agreement may be
                                immediately terminated by PollyTech in the event
                                of a breach of this Agreement by Contractor, or
                                if Contractor is convicted of or charged with a
                                felony or other crime or moral turpitude or
                                otherwise conducts himself or herself in a
                                manner which in the sole opinion of PollyTech
                                could injure the business reputation of
                                PollyTech.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>Non-Solicitation.</strong> Subsequent to
                                the termination or expiration of this Agreement
                                for any reason and for a period of two (2)
                                years, Contractor shall not attempt to influence
                                any employees, contractors or entities with whom
                                PollyTech conducts business, to alter, terminate
                                or otherwise modify their business or employment
                                relationship with PollyTech.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>Confidentiality.</strong> Contractor
                                recognizes and acknowledges that Contractor will
                                have access to certain Confidential Information
                                of PollyTech, its subsidiaries and affiliates
                                and persons with which PollyTech and its
                                subsidiaries do business, and that such
                                Confidential Information constitutes valuable,
                                special and unique property of PollyTech, its
                                subsidiaries and affiliates and such other
                                persons. “Confidential Information” will be
                                interpreted to include all information of any
                                sort (whether merely remembered or embodied in a
                                tangible or intangible form) that is (i) related
                                to PollyTech’s or its subsidiaries’ or
                                affiliates’ (including their predecessors)
                                current or potential business and (ii) not
                                generally or publicly known. Confidential
                                Information includes the information,
                                observations and data obtained by Contractor
                                while employed by PollyTech and its subsidiaries
                                (or any of their predecessors) concerning the
                                business or affairs of PollyTech or any of its
                                subsidiaries or affiliates, including
                                information concerning acquisition opportunities
                                in or reasonably related to PollyTech’s or its
                                subsidiaries’ or affiliates’ business or
                                industry, the identities of the current, former
                                or prospective Contractors, suppliers and
                                customers of PollyTech or its subsidiaries,
                                development, transition and transformation
                                plans, methodologies and methods of doing
                                business, strategic, marketing and expansion
                                plans, financial and business plans, financial
                                data, pricing information, Contractor lists and
                                telephone numbers, locations of sales
                                representatives, new and existing customer or
                                supplier programs and services, customer terms,
                                customer service and integration processes,
                                requirements and costs of providing service,
                                support and equipment and the terms of this
                                Agreement. Contractor agrees that he/she will
                                use the Confidential Information only as
                                necessary and only in connection with the
                                performance of his/her duties hereunder.
                                Contractor agrees that he/she will not disclose
                                to any unauthorized person or use for his/her
                                own or any other purposes (except as described
                                in the immediately preceding sentence) any
                                Confidential Information without the prior
                                written consent of PollyTech, unless and to the
                                extent that (a) the Confidential Information
                                becomes generally known to and available for use
                                by the public other than as a result of
                                Contractor’s acts or omissions or (b) Contractor
                                is ordered by a court of competent jurisdiction
                                to disclose Confidential Information; provided
                                that Contractor must (i) provide prompt written
                                notice to PollyTech of any relevant process or
                                pleadings that could lead to such an order and
                                (ii) cooperate with PollyTech to contest, object
                                to or limit such a request and, in any case,
                                when revealing, such Confidential Information to
                                such court order.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                <strong>Enforcement of Restrictions.</strong>{" "}
                                Contractor acknowledges the reasonableness of
                                the restrictions contained in Sections 6 and 6,
                                7, or 8 above and that PollyTech would not have
                                entered into this Agreement with Contractor if
                                such restrictions were not included. Contractor
                                agrees that PollyTech may, without limiting its
                                other available legal remedies, enforce the
                                restrictions in Sections 6 and 7 above by
                                obtaining equitable relief, including but not
                                limited to injunctive relief without the
                                necessity of posting bond, to enforce the same.
                                If PollyTech prevails in the enforcement of such
                                restrictions, Contractor shall pay PollyTech all
                                of its costs incurred in the enforcement of the
                                same including but not limited to reasonable
                                attorney fees. Further, the parties hereby
                                authorize a court of competent jurisdiction to
                                modify the restrictions contained in Section 6
                                and 7 to the fullest extent it deems
                                enforceable.
                            </Typography>
                        </ListItem>
                    </List>
                    <Typography variant="h6" mt={4} gutterBottom>
                        General Terms:
                    </Typography>
                    <List>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                A. <strong>Governing Law.</strong> This
                                Agreement shall be governed and construed in
                                accordance with the laws of the State of Ohio.
                                The parties agree to the exclusive jurisdiction
                                of the courts of Common Pleas of Mahoning
                                County, Ohio for the resolution of all disputes
                                arising under this Agreement.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                B. <strong>Notices.</strong> Any notices to
                                either party under this Agreement shall be in
                                writing and delivered by hand or sent by
                                nationally recognized messenger service or by
                                registered or certified mail, return receipt
                                requested, to the address set forth above or to
                                such other address as that party may hereafter
                                designate by notice. Notice shall be effective
                                when received, which shall be no greater than
                                one (1) business day after being sent by a
                                nationally recognized messenger service or three
                                days after being sent by ordinary mail.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                C. <strong>Entire Agreement.</strong> This
                                Agreement represents the entire agreement
                                between the parties. This Agreement may not be
                                amended, changed or supplemented in any way
                                except by written Agreement signed by both
                                parties.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                D.{" "}
                                <strong>Prior Agreements; Modification.</strong>{" "}
                                This Agreement supersedes and replaces all prior
                                written and oral agreements and understandings
                                between the parties with respect to the subject
                                matter addressed herein, and it may not be
                                changed or terminated orally. No modification,
                                termination or attempted waiver of any of its
                                provisions shall be valid unless in writing
                                signed by the party against whom the same is
                                sought to be enforced.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                E. <strong>Enforceability.</strong> The parties
                                agree that if any provision of this Agreement is
                                held in a final judgment or determination of any
                                court of law or administrative agency of
                                competent jurisdiction to be overbroad or
                                otherwise unenforceable in any respect, such
                                provision shall be deemed to be amended, and
                                shall be binding upon Contractor to the maximum
                                extent deemed reasonable and enforceable by such
                                court or administrative agency. If the provision
                                cannot be modified, that provision may be
                                severed and the other parts of the Agreement
                                shall remain enforceable.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body2" gutterBottom>
                                F. <strong>Indemnity.</strong> You agree to
                                defend, indemnify and hold PollyTech, its
                                principals, employees and agents free from all
                                costs, damages and expenses arising out of your
                                negligent acts or omissions. You will defend,
                                indemnify, and hold PollyTech including our
                                affiliates, subsidiaries, parents, successors
                                and assigns, and each of our respective
                                officers, directors, employees, agents, or
                                shareholders harmless from any claims, actions,
                                suits, losses, costs, liabilities and expenses
                                (including reasonable attorneys’ fees) relating
                                to or arising out of your use of the Truk-Em
                                Platform and participation in the Services,
                                including: (1) your breach of this Agreement or
                                the documents it incorporates by reference; (2)
                                your violation of any law or the rights of a
                                third party, including, Drivers, other
                                motorists, and pedestrians, as a result of your
                                own interaction with such third party; (3) any
                                allegation that any materials that you submit to
                                us or transmit through the Truk-Em Platform or
                                to us infringe or otherwise violate the
                                copyright, trademark, trade secret or other
                                intellectual property or other rights of any
                                third party; and/or (4) any other activities in
                                connection with the Services. This indemnity
                                shall be applicable without regard to the
                                negligence of any party, including any
                                indemnified person.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant={"caption"} mt={4} gutterBottom>
                                G. <strong>Notices</strong> Notices required
                                herein shall be delivered to the other party via
                                certified mail, return receipt requested to the
                                address of the party noted hereinabove.
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
                <Stack alignItems={"center"} paddingX={{ xs: 1, sm: 2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={accepted}
                                onChange={() => setAccepted(!accepted)}
                            />
                        }
                        label="I accept Truk-Em's Driver Terms of Service"
                    />
                    <Button
                        variant={"contained"}
                        disabled={!accepted}
                        onClick={handleContinue}
                        sx={{
                            mt: 2,
                            width: "30%",
                            backgroundColor: "#40ABBA",
                            ":hover": { backgroundColor: "#2a6574" },
                        }}
                    >
                        {updateUserState.status !== "loading" && "Continue"}
                        {updateUserState.status === "loading" && (
                            <CircularProgress size={24} color="inherit" />
                        )}
                    </Button>
                </Stack>
            </TermsOfServiceContainer>
        </Box>
    );
};

export default DriverTermsOfService;
