import { Box } from "@mui/material";
import { CustomOrderForm } from "@/components/dashboard/orders";
import { IDBCustomOrder } from "@/api/orders";

const EditOrderCard = ({ order }: { order: IDBCustomOrder }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
            }}
        >
            <CustomOrderForm order={order} />
        </Box>
    );
};

export default EditOrderCard;
