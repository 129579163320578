import { Box, Skeleton, Toolbar } from "@mui/material";

export const OrderDetailsToolbarSkeleton = () => {
    return (
        <Toolbar disableGutters>
            <Skeleton
                variant="rectangular"
                width={"120px"}
                sx={{ borderRadius: 1 }}
            />
            <Box flexGrow={1} />

            <Skeleton
                variant="rectangular"
                width={"120px"}
                sx={{ borderRadius: 1 }}
            />
        </Toolbar>
    );
};
