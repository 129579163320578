import { getAllStores } from "@/api/store";
import { DataTable } from "@/components/dashboard";
import EditIcon from "@mui/icons-material/Edit";
import {
    GridActionsCellItem,
    GridColDef,
    GridRowParams,
} from "@mui/x-data-grid";
import { useAsync } from "@react-hookz/web";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFilterModel } from "@/hooks";

interface IStoreRow {
    id: number;
    name: string;
    store_address: string;
}

export const StoresTable = () => {
    const navigate = useNavigate();
    const [storeRows, setStoreRows] = useState<IStoreRow[]>([]);
    const [getStoresState, getStoresActions] = useAsync(getAllStores);

    const { filterModel, handleFilterModelChange } = useFilterModel();

    // set default pagination model
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    useEffect(() => {
        getStoresActions.execute(
            paginationModel.page + 1,
            paginationModel.pageSize,
            filterModel
        );
    }, [
        getStoresActions,
        paginationModel.page,
        paginationModel.pageSize,
        filterModel,
    ]);

    useEffect(() => {
        if (getStoresState.status === "success" && getStoresState.result) {
            const data = getStoresState.result.items.map((store) => {
                return {
                    id: store.id,
                    name: store.name,
                    store_address: [
                        store.store_address.address1,
                        store.store_address.address2,
                        store.store_address.city,
                        store.store_address.state,
                        store.store_address.zip_code,
                    ]
                        .filter(Boolean)
                        .join(", "),
                } as IStoreRow;
            });

            setStoreRows(data);
        }
    }, [getStoresState]);

    const autosizeOptions = {
        columns: ["name", "store_address"],
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    };

    // Column Definition for the Table
    const columns: GridColDef<IStoreRow>[] = useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                display: "flex",
                sortingOrder: ["desc", "asc"],
            },
            {
                field: "name",
                headerName: "Store Name",
                display: "flex",
            },
            {
                field: "store_address",
                headerName: "Store Address",
                display: "flex",
            },
            {
                field: "actions",
                type: "actions",
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit store"
                        onClick={() =>
                            navigate(`/dashboard/stores/${params.row.id}/edit`)
                        }
                    />,
                ],
            },
        ],
        [navigate]
    );

    // memoize rowCount to avoid resetting the page to 0 when the data is loading
    const rowCountRef = useRef(getStoresState.result?.total || 0);

    const rowCount = useMemo(() => {
        if (getStoresState.result?.total !== undefined) {
            rowCountRef.current = getStoresState.result?.total;
        }
        return rowCountRef.current;
    }, [getStoresState.result?.total]);

    return (
        <>
            <DataTable
                loading={getStoresState.status === "loading"}
                rows={storeRows}
                columns={columns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "desc" }],
                    },
                }}
                autoSize
                autosizeOptions={autosizeOptions}
                onFilterModelChange={handleFilterModelChange}
                onSortModelChange={handleFilterModelChange}
                filterMode={"server"}
                sortingMode={"server"}
                paginationMode={"server"}
                rowCount={rowCount}
                pageSizeOptions={[10, 25, 50]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </>
    );
};

export default StoresTable;
