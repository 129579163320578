import { Box, Toolbar } from "@mui/material";
import { EnhancedTableToolBar } from "@/components/dashboard";
import { ChangePasswordCard } from "./components/ChangePasswordCard";

export const ChangePassword = () => {
    return (
        <Box bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"} p={"1em"}>
            {/* Spacing for mobile app bar */}
            <Toolbar sx={{ display: { sm: "none" } }} />
            <EnhancedTableToolBar title={`Change Password`} />

            <ChangePasswordCard />
        </Box>
    );
};

export default ChangePassword;
