import { Box } from "@mui/material";
import React from "react";
import { BackButton } from "@/components/ui/BackButton";
import { CreateUserCard } from "./CreateUserCard";

interface ICreateUserProps {
    setShowCreateUser: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateUser: React.FC<ICreateUserProps> = ({
    setShowCreateUser,
}) => {
    return (
        <Box p={"1em"} bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"}>
            <Box>
                <BackButton onClick={() => setShowCreateUser(false)} />
                <CreateUserCard />
            </Box>
        </Box>
    );
};
