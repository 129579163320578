import { Card, InputAdornment, Stack, Typography } from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { FormButton, FormContainer, FormTextFieldElement } from "@rhf-kit/mui";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { IDeliveryWindowPricing, updateDeliveryWindow } from "@/api/settings";

interface IDeliveryWindowsFormProps {
    deliveryWindows: IDeliveryWindowPricing[];
}

export const DeliveryWindowsForm = ({
    deliveryWindows,
}: IDeliveryWindowsFormProps) => {
    const [updateDeliveryWindowState, updateDeliveryWindowActions] = useAsync(
        async (updatedDeliveryWindows: IDeliveryWindowPricing[]) => {
            for (const deliveryWindow of updatedDeliveryWindows) {
                await updateDeliveryWindow(deliveryWindow);
            }
        }
    );

    const defaultValues = deliveryWindows
        .sort((a, b) => a.id - b.id)
        .reduce(
            (acc, item) => {
                acc[item.window_type] = (item.additional_cost / 100).toFixed(2);
                return acc;
            },
            {} as Record<string, string>
        );

    const handleSubmit = (data: Record<string, string>) => {
        const updatedDeliveryWindows = deliveryWindows.map((deliveryWindow) => {
            return {
                ...deliveryWindow,
                additional_cost:
                    parseFloat(data[deliveryWindow.window_type]) * 100,
            };
        });

        updateDeliveryWindowActions.execute(updatedDeliveryWindows);
    };

    useEffect(() => {
        if (updateDeliveryWindowState.status === "success") {
            enqueueSnackbar("Delivery window pricing updated successfully!", {
                variant: "success",
            });
        }

        if (updateDeliveryWindowState.status === "error") {
            enqueueSnackbar("Failed to update delivery window pricing.", {
                variant: "error",
            });
        }
    }, [updateDeliveryWindowState.status]);

    return (
        <Card sx={{ borderRadius: "10px", p: 3 }}>
            <Typography
                variant="h6"
                fontWeight={600}
                textAlign={"left"}
                gutterBottom
            >
                Delivery Window Pricing
            </Typography>
            <FormContainer
                defaultValues={defaultValues}
                onSuccess={handleSubmit}
            >
                <Stack spacing={4} my={4}>
                    {deliveryWindows.map((deliveryWindow) => (
                        <FormTextFieldElement
                            required
                            key={deliveryWindow.id}
                            name={deliveryWindow.window_type}
                            label={deliveryWindow.window_type}
                            type={"number"}
                            rules={{
                                min: {
                                    value: 0,
                                    message: "Price must be at least 0",
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={"start"}>
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ))}
                </Stack>
                <FormButton
                    variant="contained"
                    loading={updateDeliveryWindowState.status === "loading"}
                    loadingPosition="end"
                    sx={{
                        backgroundColor: "#40ABBA",
                        ":hover": { backgroundColor: "#2a6574" },
                    }}
                >
                    Update Delivery Windows
                </FormButton>
            </FormContainer>
        </Card>
    );
};
