import { Box } from "@mui/material";
import { StoreForm } from "@/components/dashboard";
import { BackButton } from "@/components/ui/BackButton";

interface CreateStoreProps {
    setShowCreateStore: (value: boolean) => void;
}

export const CreateStore = ({ setShowCreateStore }: CreateStoreProps) => {
    return (
        <Box p={"1em"} bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"}>
            <Box maxWidth={"md"} marginX={"auto"} gap={"1em"} pt={"1em"}>
                <BackButton onClick={() => setShowCreateStore(false)} />

                <StoreForm />
            </Box>
        </Box>
    );
};

export default CreateStore;
