import { Box, Container } from "@mui/material";

interface ITermsOfServiceContainerProps {
    children: React.ReactNode;
}

export const TermsOfServiceContainer = ({
    children,
}: ITermsOfServiceContainerProps) => {
    return (
        <Container
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
            }}
            maxWidth={"md"}
        >
            <Box
                sx={{ bgcolor: "#FFFFFF " }}
                boxShadow={"0px 1px 1px 1px #ccc"}
                borderRadius={2}
                paddingY={{ xs: 1, sm: 2 }}
            >
                {children}
            </Box>
        </Container>
    );
};
