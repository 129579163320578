import { Box, Toolbar } from "@mui/material";
import { OrderDetails } from "@/components/dashboard/orders";

const StoreOrderDetail = () => {
    return (
        <Box bgcolor={"#F4F7FE"} minHeight={"100vh"} width={"100%"}>
            {/* Spacing for mobile app bar */}
            <Toolbar sx={{ display: { sm: "none" } }} />
            <OrderDetails />
        </Box>
    );
};

export default StoreOrderDetail;
