import { FormPasswordElement, FormButton as Button } from "@rhf-kit/mui";
import { AsyncState } from "@react-hookz/web";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";

interface IProps {
    state: AsyncState<unknown>;
}

export const ChangePasswordForm = ({ state }: IProps) => {
    const { reset } = useFormContext();

    useEffect(() => {
        if (state.status === "success") {
            enqueueSnackbar("Password updated successfully!", {
                variant: "success",
            });
            reset();
        }

        if (state.status === "error") {
            enqueueSnackbar(
                "There was an error trying to update your password.",
                {
                    variant: "error",
                }
            );
        }
    }, [reset, state.status]);

    return (
        <>
            <FormPasswordElement
                name={"currentPassword"}
                label={"Current Password"}
                margin={"normal"}
                size={"small"}
                required
                fullWidth
                autoComplete="current-password"
            />
            <FormPasswordElement
                name={"newPassword"}
                label={"New Password"}
                margin={"normal"}
                size={"small"}
                required
                fullWidth
                autoComplete="new-password"
            />
            <FormPasswordElement
                name={"confirmNewPassword"}
                label={"Confirm New Password"}
                margin={"normal"}
                size={"small"}
                required
                fullWidth
                autoComplete="new-password"
                rules={{
                    required: "Confirm new password is required",
                    validate: (value, fieldValues) =>
                        value === fieldValues.newPassword ||
                        "Passwords do not match",
                }}
            />

            <Button
                variant="contained"
                color="primary"
                sx={{
                    mt: 2,

                    backgroundColor: "#40ABBA",
                    ":hover": { backgroundColor: "#2a6574" },
                }}
                loading={state.status === "loading"}
                loadingPosition={"end"}
            >
                Change Password
            </Button>
        </>
    );
};
