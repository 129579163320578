import {
    Backdrop,
    Box,
    CircularProgress,
    Link,
    Typography,
} from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { getStore } from "@/api/store";
import { getPricing } from "@/api/settings";
import { OrderForm, StoreNotFound } from "@/components/dashboard";

export const ShippingForm = () => {
    const { store_id } = useParams();

    const [getStoreState, getStoreActions] = useAsync(getStore);
    const [getPricingState, getPricingActions] = useAsync(getPricing);

    // fetch store on mount
    useMountEffect(() => {
        getPricingActions.execute();
        if (store_id) getStoreActions.execute(store_id);
    });

    if (
        getStoreState.status === "loading" ||
        getPricingState.status === "loading"
    )
        return (
            <Backdrop
                open={getStoreState.status === "loading"}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );

    if (getStoreState.error) {
        const error = getStoreState.error as AxiosError;
        if (error.response && error.response.status === 404) {
            return <StoreNotFound />;
        }
        return (
            <Typography variant="h6">
                There was an error loading the store with the id #{store_id}.
            </Typography>
        );
    }

    if (!getStoreState.result || !getPricingState.result) return null;

    const store = getStoreState.result;
    const pricing = getPricingState.result;

    return (
        <Box
            pt={"1em"}
            bgcolor={"#F3F4F6"}
            minHeight={"100vh"}
            width={"100%"}
            overflow={"auto"}
        >
            {/* Store Info */}
            <Typography
                variant="h5"
                textAlign={"center"}
                fontWeight={900}
                color={"#2B3674"}
            >
                Local Delivery Information
            </Typography>
            <Typography
                variant="h6"
                textAlign={"center"}
                fontWeight={600}
                color={"#2B3674"}
                mb={1}
            >
                {store.name}
            </Typography>
            <Typography
                variant="body1"
                textAlign={"center"}
                textTransform={"capitalize"}
                color={"#2B3674"}
                mb={1}
            >
                {[
                    store.store_address.address1,
                    store.store_address.address2,
                    store.store_address.city,
                    store.store_address.state,
                    store.store_address.zip_code,
                ]
                    .filter(Boolean)
                    .join(", ")}
            </Typography>

            <Box
                minHeight={"100vh"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
            >
                {/* Render Order Form with store */}
                <Box p={"1em"} maxWidth={"xl"} mx={"auto"} mb={"1em"}>
                    <OrderForm store={store} pricing={pricing} />
                </Box>

                {/* Footer */}
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                    bgcolor="primary.main"
                    color="white"
                    padding={2}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <img
                            src="/Truk-Em.png"
                            alt="Truk-Em Logo"
                            width="100"
                        />
                        <Typography
                            align="center"
                            variant="body2"
                            fontWeight={900}
                            gutterBottom
                        >
                            Powered By Truk-Em
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" fontWeight={700}>
                            Contact Info:
                        </Typography>
                        <Link
                            variant="body2"
                            color="#fff"
                            marginRight={1}
                            href="mailto:support@trukem.com"
                        >
                            support@trukem.com
                        </Link>
                        <Typography variant="body2">
                            +1 (330) 423-5438
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ShippingForm;
