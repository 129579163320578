import { Store } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { IStore } from "@/api/store.ts";

interface IStoreAutoCompleteOptionProps {
    option: IStore;
    props: React.HTMLAttributes<HTMLLIElement>;
}

export const StoreAutoCompleteOption: React.FC<
    IStoreAutoCompleteOptionProps
> = ({ option, props }) => {
    const formattedStoreAddress = `${option.store_address.address1}, ${
        option.store_address.address2 && option.store_address.address2 !== ""
            ? option.store_address.address2 + ", "
            : ""
    }${option.store_address.city}, ${option.store_address.state}, ${
        option.store_address.zip_code
    }`;

    return (
        <li {...props}>
            <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                    <Store sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                    <Box
                        component="span"
                        sx={{ fontWeight: option.name ? "bold" : "regular" }}
                        textTransform={"capitalize"}
                    >
                        {option.name}
                    </Box>

                    <Typography
                        variant="body2"
                        color="text.secondary"
                        textTransform={"capitalize"}
                    >
                        {formattedStoreAddress}
                    </Typography>
                </Grid>
            </Grid>
        </li>
    );
};
