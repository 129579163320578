import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Button,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const EditOrderMenu = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();

    if (!mobile) {
        return (
            <Button
                variant="contained"
                size="small"
                endIcon={<EditIcon />}
                onClick={() => navigate("edit")}
                sx={{
                    backgroundColor: "#40ABBA",
                    ":hover": { backgroundColor: "#2a6574" },
                }}
            >
                Edit
            </Button>
        );
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuOptions = [
        {
            label: "Edit Order",
            onClick: () => {
                navigate("edit");
                handleClose();
            },
        },
    ];

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {menuOptions.map(({ label, onClick }) => (
                    <MenuItem key={label} onClick={onClick}>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{label} </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
