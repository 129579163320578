import { getPaymentIntent, ICustomOrderPaymentIntent } from "@/api/orders.ts";
import { PaymentIntentNotFound } from "@/components/dashboard";
import { BackButton } from "@/components/ui/BackButton.tsx";
import { CheckoutForm } from "@/pages/order/components/CheckoutForm.tsx";
import {
    Backdrop,
    Box,
    CircularProgress,
    TextField,
    Typography,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const CustomOrderCheckout = () => {
    const { payment_intent_id } = useParams();
    const navigate = useNavigate();

    const {
        isPending,
        isError,
        data: paymentIntent,
    } = useQuery<ICustomOrderPaymentIntent>({
        queryKey: ["checkout-payment-intent", payment_intent_id],
        queryFn: () => {
            if (payment_intent_id) {
                return getPaymentIntent(payment_intent_id);
            } else throw Error("No payment intent id");
        },
    });

    if (isPending)
        return (
            <Backdrop
                open={isPending}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );

    if (isError) return <PaymentIntentNotFound />;

    if (!paymentIntent) return null;

    const pickupAddress = `${paymentIntent.metadata.pickup_address_address1}, ${paymentIntent.metadata.pickup_address_city}, ${paymentIntent.metadata.pickup_address_state} ${paymentIntent.metadata.pickup_address_zip_code}`;
    const deliveryAddress = `${paymentIntent.metadata.delivery_address_address1}, ${paymentIntent.metadata.delivery_address_city}, ${paymentIntent.metadata.delivery_address_state} ${paymentIntent.metadata.delivery_address_zip_code}`;

    if (paymentIntent.status === "succeeded") {
        return <Navigate to={`/orders/create`} replace />;
    }
    return (
        <Box
            bgcolor={"#F3F4F6"}
            minHeight={"100vh"}
            width={"100%"}
            overflow={"auto"}
        >
            <Elements
                options={{ clientSecret: paymentIntent.client_secret }}
                stripe={stripePromise}
            >
                <Box p={"1em"}>
                    {/* Payment Information Header */}
                    <Typography
                        variant="h5"
                        textAlign={"center"}
                        fontWeight={900}
                        color={"#2B3674"}
                    >
                        Order Checkout
                    </Typography>
                    <Box maxWidth={"lg"} mx={"auto"} my={"1em"}>
                        {/* Back button sends back payment intent state */}
                        <BackButton
                            onClick={() =>
                                navigate(`/orders/create`, {
                                    state: paymentIntent,
                                })
                            }
                        />
                        <Box
                            display={"flex"}
                            flexDirection={{ xs: "column", md: "row" }}
                            gap={4}
                            mb={2}
                        >
                            {/* Pickup address */}
                            <TextField
                                fullWidth
                                size="small"
                                label="Pickup Address"
                                disabled
                                variant="outlined"
                                value={pickupAddress}
                            />
                            {/* Delivery address */}
                            <TextField
                                fullWidth
                                size="small"
                                label="Delivery Address"
                                disabled
                                variant="outlined"
                                value={deliveryAddress}
                            />
                        </Box>
                        {/* Render Checkout Form */}
                        <CheckoutForm paymentIntent={paymentIntent} />
                    </Box>
                </Box>
            </Elements>
        </Box>
    );
};

export default CustomOrderCheckout;
