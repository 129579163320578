import { IStore, deleteStore, getStore } from "@/api/store.ts";
import { useAuth } from "@/components/context/useAuth";
import { EditStoreHours, EnhancedTableToolBar } from "@/components/dashboard";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Toolbar,
} from "@mui/material";
import { useAsync } from "@react-hookz/web";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import EditStoreForm from "./components/EditStoreForm";

const EditStores = () => {
    const { store_id } = useParams();
    const [storeState, storeActions] = useAsync(getStore);
    const [deleteStoreState, deleteStoreActions] = useAsync(deleteStore);
    const [store, setStore] = useState<IStore>();
    const [open, setOpen] = useState(false);
    const { user } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (store_id) {
            storeActions.execute(store_id);
        }
    }, [store_id, storeActions]);

    useEffect(() => {
        if (storeState.status === "success" && storeState.result) {
            setStore(storeState.result);
        }
    }, [storeState]);

    const handleDelete = () => {
        if (store_id) {
            deleteStoreActions.execute(Number(store_id));
        }
        setOpen(false);
    };

    useEffect(() => {
        if (deleteStoreState.status === "success") {
            navigate("/dashboard/stores");
            enqueueSnackbar(`${store?.name} was successfully deleted.`, {
                variant: "success",
            });
            navigate(0);
        }
    }, [deleteStoreState, navigate, store?.name]);

    if (!store) {
        if (storeState.status === "error") {
            return <Navigate to="/404" replace />;
        } else {
            return (
                <Backdrop
                    open={true}
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                >
                    <CircularProgress sx={{ color: "#ffffff" }} />
                </Backdrop>
            );
        }
    }

    const renderDeleteButton = () => {
        if (!user?.is_admin) {
            return null;
        }
        return (
            <Button
                sx={{
                    fontWeight: "bold",
                    mt: "1em",
                }}
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setOpen(true)}
                disabled={deleteStoreState.status === "loading"}
            >
                Delete Store
            </Button>
        );
    };

    return (
        <>
            <Box
                padding={"1em"}
                bgcolor={"#F4F7FE"}
                minHeight={"100vh"}
                width={"100%"}
            >
                {/* Spacing for mobile app bar */}
                <Toolbar sx={{ display: { sm: "none" } }} />
                <EnhancedTableToolBar title={`Edit ${store.name}`} />
                <Box
                    maxWidth={"xl"}
                    marginX={"auto"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"1em"}
                >
                    <Stack
                        direction={{ sm: "column", lg: "row" }}
                        width={"100%"}
                        gap={2}
                    >
                        <Stack flex={7} width={"100%"}>
                            <EditStoreForm store={store} />
                            <Box display={{ xs: "none", lg: "block" }}>
                                {renderDeleteButton()}
                            </Box>
                        </Stack>
                        <Stack flex={5}>
                            <EditStoreHours store_hours={store.store_hours} />
                        </Stack>
                        <Box display={{ lg: "none" }}>
                            {renderDeleteButton()}
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete {store.name}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {store.name}? This
                        action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button color="error" onClick={handleDelete} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditStores;
