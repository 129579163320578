import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import React from "react";

interface IBackButtonProps {
    href?: string;
    onClick?: () => void;
}

export const BackButton: React.FC<IBackButtonProps> = ({ href, onClick }) => {
    return (
        <Button
            href={href}
            onClick={onClick}
            sx={{ mb: "1em", color: "#40ABBA" }}
            size={"small"}
            variant={"text"}
            startIcon={<ArrowBackIcon />}
        >
            Back
        </Button>
    );
};
