import { useAuth } from "@/components/context/useAuth";
import { AdminOrders } from "./components/Orders";

export const AdminOrdersDashboard = () => {
    const { user } = useAuth();

    if (!user?.is_admin) {
        return <div>Unauthorized</div>;
    }

    return <AdminOrders />;
};

export default AdminOrdersDashboard;
