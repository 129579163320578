import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { ConditionalContainer } from "./ConditionalContainer";

export const StoreNotFound = () => {
    const { store_id } = useParams<{ store_id: string }>();

    return (
        <ConditionalContainer>
            <StoreRoundedIcon sx={{ fontSize: 128, color: "#40ABBA", mb: 2 }} />
            <Typography variant="h5" fontWeight={600}>
                Store {store_id && `#${store_id}`} Not Found
            </Typography>
        </ConditionalContainer>
    );
};
