import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Box, Card, Stack, Typography } from "@mui/material";
import { DBStoreOrder } from "@/api/store";

interface IOrderCustomerInfoCardProps {
    order: DBStoreOrder;
}

export const OrderDriverInfoCard = ({ order }: IOrderCustomerInfoCardProps) => {
    if (order.driver) {
        return (
            <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
                <Box mb={2}>
                    <Stack direction={"row"} alignItems={"center"} mb={1}>
                        <LocalShippingIcon
                            sx={{ marginRight: 1 }}
                            color="inherit"
                        />
                        <Typography
                            variant="body2"
                            fontWeight={600}
                            textAlign={"left"}
                        >
                            Driver
                        </Typography>
                    </Stack>
                    <Typography variant="body2" textAlign={"left"}>
                        {order.driver.first_name} {order.driver.last_name}
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography
                        mb={1}
                        variant="body2"
                        fontWeight={600}
                        textAlign={"left"}
                    >
                        Contact Information
                    </Typography>
                    <Typography variant="body2" textAlign={"left"}>
                        {order.driver.email}
                    </Typography>
                </Box>
            </Card>
        );
    }
    return (
        <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
            <Box mb={2}>
                <Stack direction={"row"} alignItems={"center"} mb={1}>
                    <LocalShippingIcon
                        sx={{ marginRight: 1 }}
                        color="inherit"
                    />
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        textAlign={"left"}
                    >
                        Driver
                    </Typography>
                </Stack>
            </Box>
            <Box mb={2}>
                <Typography
                    mb={1}
                    variant="body2"
                    fontWeight={600}
                    textAlign={"left"}
                >
                    No driver assigned
                </Typography>
            </Box>
        </Card>
    );
};
