import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Box, Card, Link, Stack, Typography } from "@mui/material";
import { DBStoreOrder } from "@/api/store";

interface IOrderCustomerInfoCardProps {
    order: DBStoreOrder;
}

export const OrderCustomerInfoCard = ({
    order,
}: IOrderCustomerInfoCardProps) => {
    const addressString = `${order.delivery_address.address1}, ${order.delivery_address.city}, ${order.delivery_address.state} ${order.delivery_address.zip_code}`;
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        addressString
    )}`;
    return (
        <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
            <Box mb={2}>
                <Stack direction={"row"} alignItems={"center"} mb={1}>
                    <PersonRoundedIcon
                        sx={{ marginRight: 1 }}
                        color="inherit"
                    />
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        textAlign={"left"}
                    >
                        Customer
                    </Typography>
                </Stack>
                <Typography variant="body2" textAlign={"left"}>
                    {order.first_name} {order.last_name}
                </Typography>
            </Box>
            <Box mb={2}>
                <Typography
                    mb={1}
                    variant="body2"
                    fontWeight={600}
                    textAlign={"left"}
                >
                    Contact Information
                </Typography>
                <Typography variant="body2" textAlign={"left"}>
                    {order.email}
                </Typography>
                <Typography variant="subtitle2" textAlign={"left"}>
                    {order.phone_number}
                </Typography>
            </Box>

            <Box mb={2}>
                <Typography
                    mb={1}
                    variant="body2"
                    fontWeight={600}
                    textAlign={"left"}
                >
                    Delivery Address
                </Typography>
                <Typography variant="body2" textAlign={"left"}>
                    {order.delivery_address.address1}
                </Typography>
                {order.delivery_address.address2 && (
                    <Typography variant="body2" textAlign={"left"}>
                        {order.delivery_address.address2}
                    </Typography>
                )}
                <Typography variant="body2" textAlign={"left"}>
                    {order.delivery_address.city} {order.delivery_address.state}{" "}
                    {order.delivery_address.zip_code}
                </Typography>
            </Box>

            <Link
                variant="body2"
                underline="hover"
                href={googleMapsLink}
                target="_blank"
            >
                View on map
            </Link>
        </Card>
    );
};
