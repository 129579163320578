import { buildQuery, IFilter, IPage } from "@/api/api.ts";
import axios from "axios";
import { UserType } from "./profile";

const BASE_URL = import.meta.env.VITE_API_URL;
const API_URL = `${BASE_URL}/store`;

export interface IAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
}

interface IStoreAddress extends IAddress {
    id: number;
    store_id: number;
}

export interface IHours {
    day: number;
    open_time?: Date | string;
    close_time?: Date | string;
    open: boolean;
}

export interface IStoreHours extends IHours {
    id: number;
    store_id: number;
}

export enum ItemSize {
    SMALL = "48x36x6 - 60x48x12",
    MEDIUM = "60x48x12 - 84x60x18",
    LARGE = "84x60x18 - 96x68x24",
    EXTRA_LARGE = "96x68x24",
}

export enum DeliveryWindow {
    DEFAULT = "7 Days",
    FIVE_DAYS = "5 Days",
    THREE_DAYS = "3 Days",
    TWO_DAYS = "2 Days",
}

export interface IStoreOrder {
    id?: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    order_location?: string;
    delivery_address: IAddress;
    item_size: string;
    delivery_window: string;
    item_description: string;
    estimated_value: string;
    item_quantity: number;
    floor_level: number;
    elevator_available: boolean;
    assembly_required: boolean;
    max_weight: boolean;
    needs_wrapped: boolean;
    store_id: number;
    invoice_file?: string;
    invoice_uploaded_at?: string;
    dropoff_instructions: string;
    driver_bonus?: string;
}

export interface IMetaDataResponse {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    order_location: string;
    delivery_address_address1: string;
    delivery_address_address2: string;
    delivery_address_city: string;
    delivery_address_state: string;
    delivery_address_zip_code: string;
    item_description: string;
    estimated_value: string;
    item_quantity: number;
    assembly_cost: string;
    base_price: string;
    delivery_window_cost: string;
    distance_cost: string;
    floor_level_cost: string;
    max_weight_cost: string;
    max_weight: boolean;
    needs_wrapped: boolean;
    distance: string;
    distance_multiplier: string;
    delivery_window: DeliveryWindow;
    item_size: ItemSize;
    floor_level: number;
    elevator_available: boolean;
    assembly_required: boolean;
    dropoff_instructions: string;
    referral?: string;
    discount?: string;
}

export interface IPaymentIntent {
    id: string;
    amount: number;
    status: string;
    client_secret: string;
    metadata: IMetaDataResponse;
}

export interface IUpdateOrder {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone_number?: string;
    delivery_address?: IAddress;
    item_description?: string;
    estimated_value?: string;
    item_quantity?: number;
    item_size?: string;
    delivery_window?: string;
    floor_level?: number;
    elevator_available?: boolean;
    assembly_required?: boolean;
    max_weight?: boolean;
    needs_wrapped?: boolean;
    dropoff_instructions?: string;
    driver_bonus?: string;
}

export interface DBStoreOrder extends IStoreOrder {
    id: number;
    created_at: string;
    accepted: boolean;
    accepted_time: string;
    picked_up: boolean;
    picked_up_time: string;
    expected_pickup_time: string;
    delivered: boolean;
    delivered_time: string;
    paid_driver: boolean;
    payment_intent_id?: string;
    referral?: string;
    driver: UserType;
}

export interface IStore {
    id: number;
    name: string;
    store_address: IStoreAddress;
    orders: DBStoreOrder[];
    store_hours: IStoreHours[];
}

export interface ICreateStore {
    name: string;
    store_address: IAddress;
}

export interface IUpdateStore extends ICreateStore {}

export interface IStoreAccount extends UserType {
    store_id: number;
}

export interface ICreateStoreOrder extends IStoreOrder {
    payment_intent_id: string;
}

export const createStore = async (data: ICreateStore) => {
    const result: { data: IStore } = await axios.post(`${API_URL}`, data);
    return result.data;
};

export const deleteStore = async (store_id: number) => {
    const result: { data: IStore } = await axios.delete(
        `${API_URL}/${store_id}`
    );
    return result.data;
};

export const getAllStores = async (
    page: number = 1,
    size: number = 50,
    filter?: IFilter
) => {
    const result = await axios.get<IPage<IStore>>(
        `${API_URL}?page=${page}&size=${size}&${buildQuery(filter)}`,
        {
            headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0",
            },
        }
    );
    return result.data;
};

export const addStoreAccount = async (store_id: number, user_id: number) => {
    const result: { data: IStoreAccount } = await axios.post(
        `${API_URL}/${store_id}/accounts?user_id=${user_id}`
    );
    return result.data;
};

export const getStore = async (store_id: string | number) => {
    const result: { data: IStore } = await axios.get(`${API_URL}/${store_id}`);
    return result.data;
};

export const getStoreAccounts = async (
    store_id: number,
    page: number = 1,
    size: number = 50
) => {
    const result = await axios.get<IPage<IStoreAccount>>(
        `${API_URL}/${store_id}/accounts?page=${page}&size=${size}`
    );
    return result.data;
};

export const removeStoreAccount = async (store_id: number, user_id: number) => {
    const result: { data: IStoreAccount } = await axios.delete(
        `${API_URL}/${store_id}/accounts?user_id=${user_id}`
    );
    return result.data;
};

export const getStoreOrders = async (
    store_id: number,
    page: number = 1,
    size: number = 50,
    filter: IFilter
) => {
    const result = await axios.get<IPage<DBStoreOrder>>(
        `${API_URL}/${store_id}/orders?page=${page}&size=${size}&${buildQuery(
            filter
        )}`
    );
    return result.data;
};

export const updateStore = async (store_id: number, data: IUpdateStore) => {
    const result: { data: IStore } = await axios.put(
        `${API_URL}/${store_id}`,
        data
    );
    return result.data;
};

export const updateStoreOrder = async (
    store_id: number,
    order_id: number,
    data: IUpdateOrder
) => {
    const result: { data: IStoreOrder } = await axios.put(
        `${API_URL}/${store_id}/orders/${order_id}`,
        data
    );
    return result.data;
};

export const createPaymentIntent = async (
    store_id: number,
    order: IStoreOrder
) => {
    const result: { data: { id: string } } = await axios.post(
        `${API_URL}/${store_id}/orders/payment`,
        order
    );
    return result.data;
};

export const getPaymentIntent = async (
    store_id: number | string,
    payment_intent_id: string
) => {
    const result: { data: IPaymentIntent } = await axios.get(
        `${API_URL}/${store_id}/orders/payment/${payment_intent_id}`,
        {
            headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0",
            },
        }
    );
    return result.data;
};

export const createOrder = async (store_id: number, order: IStoreOrder) => {
    const result = await axios.post(`${API_URL}/${store_id}/orders`, order);
    return result.data;
};

export const softDeleteOrder = async (store_id: number, order_id: number) => {
    const result = await axios.delete(
        `${API_URL}/${store_id}/orders/${order_id}`
    );
    return result.data;
};

export const updateStoreHours = async (
    store_id: number | string,
    hours: IHours,
    id: number
) => {
    const result: { data: IStoreHours } = await axios.put(
        `${API_URL}/${store_id}/hours/${id}`,
        hours
    );
    return result.data;
};

export const createStoreHours = async (
    store_id: number | string,
    hours: IHours
) => {
    const result: { data: IStoreHours } = await axios.post(
        `${API_URL}/${store_id}/hours`,
        hours
    );
    return result.data;
};
