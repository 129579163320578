import { IStoreHours } from "@/api/store.ts";
import { Card, Stack, Typography } from "@mui/material";
import { FormContainer } from "@rhf-kit/mui";

import { useParams } from "react-router-dom";

import { StoreHoursDay } from "@/pages/store/components/StoreHoursDay.tsx";
import { DAYS_OF_WEEK, getDefaultStoreHours } from "@/utils";

interface EditStoreHoursProps {
    store_hours: IStoreHours[];
}

export const EditStoreHours = ({ store_hours }: EditStoreHoursProps) => {
    const { store_id } = useParams();

    const defaultValues = getDefaultStoreHours(store_hours, store_id);

    return (
        <Card sx={{ borderRadius: "10px", p: 3 }}>
            <Typography
                variant="h6"
                fontWeight={600}
                textAlign={"left"}
                gutterBottom
            >
                {store_hours ? "Edit" : "Add"} Store Hours
            </Typography>

            <FormContainer defaultValues={defaultValues}>
                <Stack>
                    {defaultValues &&
                        DAYS_OF_WEEK.map((day) => (
                            <StoreHoursDay key={day} day={day} />
                        ))}
                </Stack>
            </FormContainer>
        </Card>
    );
};
