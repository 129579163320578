import { FormScreenContainer } from "@/components/dashboard/forms/FormContainers";
import { Typography } from "@mui/material";

const Page404 = () => {
    return (
        <FormScreenContainer>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">Page Not Found</Typography>
        </FormScreenContainer>
    );
};

export default Page404;
