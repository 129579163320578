export const formatDriverPay = (pay: number) => {
    // divide by 100 to get the percentage
    const amount = pay / 100;
    // return 70% of the pay
    return `$${(amount * 0.7).toFixed(2)}`;
};

export const formatPay = (pay: number) => {
    return `$${(pay / 100).toFixed(2)}`;
};
