import { Box, Card, Divider, Skeleton } from "@mui/material";

export const OrderLineSkeleton = () => {
    return (
        <Card sx={{ width: "100%", borderRadius: "10px", p: 1.5 }}>
            <Box mb={2}>
                <Skeleton
                    variant="rectangular"
                    width={"200px"}
                    sx={{ borderRadius: 1 }}
                />

                <Divider sx={{ marginY: 1 }} />
                <Skeleton />
                <Skeleton sx={{ width: "80%" }} />
            </Box>

            <Box mb={2}>
                <Skeleton />
                <Skeleton sx={{ width: "80%" }} />
            </Box>

            <Box mb={2}>
                <Skeleton />
                <Skeleton sx={{ width: "80%" }} />
            </Box>

            <Box mb={2}>
                <Skeleton />
                <Skeleton sx={{ width: "80%" }} />
            </Box>

            <Box mb={2}>
                <Skeleton />
                <Skeleton sx={{ width: "80%" }} />
            </Box>

            <Box mb={2}>
                <Skeleton />
                <Skeleton sx={{ width: "80%" }} />
            </Box>

            <Box mb={2}>
                <Skeleton />
                <Skeleton sx={{ width: "80%" }} />
            </Box>
        </Card>
    );
};
