import { useAsync } from "@react-hookz/web";
import { FormContainer } from "@rhf-kit/mui";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ICustomOrder,
    IDBCustomOrder,
    updateCustomOrder,
    createPaymentIntent,
    ICustomOrderPaymentIntent,
} from "@/api/orders";
import { getCustomInitialFormData } from "@/utils";
import { CustomOrderFormElements } from "@/components/dashboard/orders/process/CustomOrderFormElements.tsx";
import { IPricing } from "@/api/settings";

export interface OrderFormData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    pickup_streetAddress1: string;
    pickup_streetAddress2: string;
    pickup_city: string;
    pickup_state: string;
    pickup_zipCode: string;
    delivery_streetAddress1: string;
    delivery_streetAddress2: string;
    delivery_city: string;
    delivery_state: string;
    delivery_zipCode: string;
    itemSize: string;
    deliveryWindow: string;
    itemFloor: number;
    requiresAssembly: boolean;
    weightLimit: boolean;
    needsWrapped: boolean;
    elevatorAvailable: boolean;
    itemDescription: string;
    estimatedValue: string;
    itemQuantity: number;
    addReferral: string;
    dropoffInstructions: string;
}

interface IOrderFormProps {
    order?: IDBCustomOrder;
    pricing?: IPricing;
}

export const CustomOrderForm = ({ order, pricing }: IOrderFormProps) => {
    const { state } = useLocation() as { state: ICustomOrderPaymentIntent };
    const navigate = useNavigate();

    const initialValues: OrderFormData = getCustomInitialFormData(order, state);

    // Api call to update order
    const [updateOrderState, updateOrderActions] = useAsync(updateCustomOrder);

    // Api call to create payment intent
    const [createPaymentIntentState, createPaymentIntentActions] =
        useAsync(createPaymentIntent);

    // form submit handler - update order or create payment intent
    const onSubmit = (data: OrderFormData) => {
        // if order, update order
        if (order) {
            const updateOrderData: ICustomOrder = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone_number: data.phoneNumber,
                pickup_address: {
                    address1: data.pickup_streetAddress1,
                    address2: data.pickup_streetAddress2,
                    city: data.pickup_city,
                    state: data.pickup_state,
                    zip_code: data.pickup_zipCode,
                },
                delivery_address: {
                    address1: data.delivery_streetAddress1,
                    address2: data.delivery_streetAddress2,
                    city: data.delivery_city,
                    state: data.delivery_state,
                    zip_code: data.delivery_zipCode,
                },
                item_size: data.itemSize,
                item_quantity: data.itemQuantity,
                delivery_window: data.deliveryWindow,
                floor_level: data.itemFloor,
                assembly_required: data.requiresAssembly,
                max_weight: data.weightLimit,
                needs_wrapped: data.needsWrapped,
                elevator_available: data.elevatorAvailable,
                item_description: data.itemDescription,
                estimated_value: data.estimatedValue,
                referral: data.addReferral,
                dropoff_instructions: data.dropoffInstructions,
            };

            updateOrderActions.execute(order.id, updateOrderData);
        }

        // if no order, create payment intent
        if (!order) {
            const orderData: ICustomOrder = {
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone_number: data.phoneNumber,
                pickup_address: {
                    address1: data.pickup_streetAddress1,
                    address2: data.pickup_streetAddress2,
                    city: data.pickup_city,
                    state: data.pickup_state,
                    zip_code: data.pickup_zipCode,
                },
                delivery_address: {
                    address1: data.delivery_streetAddress1,
                    address2: data.delivery_streetAddress2,
                    city: data.delivery_city,
                    state: data.delivery_state,
                    zip_code: data.delivery_zipCode,
                },
                item_size: data.itemSize,
                item_quantity: data.itemQuantity,
                delivery_window: data.deliveryWindow,
                floor_level: data.itemFloor,
                assembly_required: data.requiresAssembly,
                max_weight: data.weightLimit,
                needs_wrapped: data.needsWrapped,
                elevator_available: data.elevatorAvailable,
                item_description: data.itemDescription,
                estimated_value: data.estimatedValue,
                referral: data.addReferral,
                dropoff_instructions: data.dropoffInstructions,
            };

            createPaymentIntentActions.execute(orderData);
        }
    };

    // handle success and error states
    useEffect(() => {
        if (updateOrderState.status === "success") {
            enqueueSnackbar("Order updated successfully", {
                variant: "success",
            });
            navigate(-1);
        }

        if (updateOrderState.status === "error") {
            enqueueSnackbar("Failed to update order", { variant: "error" });
        }
    }, [navigate, updateOrderState]);

    useEffect(() => {
        if (
            createPaymentIntentState.status === "success" &&
            createPaymentIntentState.result
        ) {
            console.log("result", createPaymentIntentState.result);
            navigate(`/orders/${createPaymentIntentState.result.id}/checkout`);
        }
    });

    return (
        <FormContainer defaultValues={initialValues} onSuccess={onSubmit}>
            <CustomOrderFormElements
                state={updateOrderState && createPaymentIntentState}
                order={order}
                pricing={pricing}
            />
        </FormContainer>
    );
};
