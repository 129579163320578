import { useAuth } from "@/components/context/useAuth";
import { AdminCustomOrders } from "./components/CustomOrders";

export const AdminCustomOrdersDashboard = () => {
    const { user } = useAuth();

    if (!user?.is_admin) {
        return <div>Unauthorized</div>;
    }

    return <AdminCustomOrders />;
};

export default AdminCustomOrdersDashboard;
