import { getDiscountCodes, IDiscountCode } from "@/api/settings";
import { CreateDiscountModal } from "@/components/dashboard/discount/CreateDiscountModal";
import { DiscountDeleteIcon } from "@/components/dashboard/discount/DeleteDiscountIcon";
import { UpdateDiscountModal } from "@/components/dashboard/discount/UpdateDiscountModal";
import { Edit } from "@mui/icons-material";
import {
    Box,
    Card,
    Divider,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import { FormButton } from "@rhf-kit/mui";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const DiscountCodesForm = () => {
    const { isPending, isError, data, refetch } = useQuery({
        queryKey: ["admin-discount-codes"],
        queryFn: getDiscountCodes,
    });

    const [create, setCreate] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [selected, setSelected] = useState<IDiscountCode>();

    if (isPending || !data) {
        return <Skeleton height={200} />;
    }

    if (isError) {
        throw new Error("Could not get discount codes");
    }

    return (
        <Card sx={{ borderRadius: "10px", p: 3 }}>
            <Typography
                variant="h6"
                fontWeight={600}
                textAlign="left"
                gutterBottom
            >
                Discount Codes
            </Typography>
            <Stack spacing={2} my={2}>
                {data.items.length === 0 && (
                    <Typography>No discount codes</Typography>
                )}
                {data.items.map((discount, index) => (
                    <>
                        <Stack
                            direction={"row"}
                            key={`discount-${index}`}
                            sx={{ justifyContent: "space-between" }}
                            display="flex"
                            alignItems="center"
                        >
                            <Typography>
                                {discount.code} (-{discount.discount * 100}%)
                            </Typography>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        setSelected(discount);
                                        setEdit(true);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                                <DiscountDeleteIcon discount={discount} />
                            </Box>
                        </Stack>
                        <Divider />
                    </>
                ))}
            </Stack>
            <FormButton
                onClick={() => setCreate(true)}
                variant="contained"
                loading={false}
                sx={{
                    backgroundColor: "#40ABBA",
                    ":hover": { backgroundColor: "#2a6574" },
                }}
            >
                New Discount Code
            </FormButton>
            <CreateDiscountModal
                open={create}
                setOpen={setCreate}
                mutateDiscounts={refetch}
            />
            <UpdateDiscountModal
                open={edit}
                discount={selected}
                setSelected={setSelected}
                setOpen={setEdit}
                mutateDiscounts={refetch}
            />
        </Card>
    );
};
