import { useAsync } from "@react-hookz/web";
import React from "react";
import { StoreAutoCompleteOption } from "./StoreAutoCompleteOption";
import { IStore, getAllStores } from "@/api/store";
import { FormAutoCompleteElement } from "@rhf-kit/mui";

export const StoreAutoComplete = () => {
    const [stores, setStores] = React.useState<IStore[]>([]);

    const [getStoresState, getStoresActions] = useAsync(getAllStores);

    // retrieve stores for autocomplete field
    React.useEffect(() => {
        getStoresActions.execute();
    }, [getStoresActions]);

    // set stores
    React.useEffect(() => {
        if (getStoresState.status === "success" && getStoresState.result) {
            setStores(getStoresState.result.items);
        }
    }, [getStoresState.status, getStoresState.result]);
    return (
        <FormAutoCompleteElement
            name={"stores"}
            label={"Store(s) (Optional)"}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => (option as IStore).name}
            noOptionsText={"No stores"}
            size="small"
            autoComplete
            autoHighlight
            multiple
            includeInputInList
            options={stores}
            textFieldProps={{ margin: "normal" }}
            loading={getStoresState.status === "loading"}
            renderOption={(props, option) => (
                <StoreAutoCompleteOption
                    key={option.id}
                    option={option}
                    props={props}
                />
            )}
        />
    );
};
