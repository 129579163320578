import { useAuth } from "@/components/context/useAuth";
import { Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import { DriverAllOrders } from "./components/AllOrders";

export const AvailableOrders = () => {
    const { user } = useAuth();

    if (!user?.is_driver && !user?.is_admin) {
        return <Navigate to="/" />;
    }

    return (
        <Box
            sx={{
                backgroundColor: "#F4F7FE",
                minHeight: "100vh",
                width: "100%",
                overflowX: "auto",
            }}
        >
            <DriverAllOrders />
        </Box>
    );
};

export default AvailableOrders;
