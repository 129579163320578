import { Card, Typography } from "@mui/material";
import { FormContainer } from "@rhf-kit/mui";
import { IItemCategory } from "@/api/settings";
import { ItemCategoriesFormElements } from "@/components/dashboard/item-categories";

export interface IItemCategoriesFormProps {
    itemCategories: IItemCategory[];
}

export const ItemCategoriesForm = ({
    itemCategories,
}: IItemCategoriesFormProps) => {
    const defaultValues = itemCategories.reduce(
        (acc, item) => {
            acc[item.id.toString()] = item.category_name;
            item.children.forEach((child) => {
                acc[child.id.toString()] = child.category_name;
            });
            return acc;
        },
        {} as Record<string, string | null>
    );

    return (
        <Card sx={{ borderRadius: "10px", p: 3 }}>
            <Typography
                variant="h6"
                fontWeight={600}
                textAlign={"left"}
                gutterBottom
            >
                Item Categories
            </Typography>
            <FormContainer defaultValues={defaultValues}>
                <ItemCategoriesFormElements itemCategories={itemCategories} />
            </FormContainer>
        </Card>
    );
};
