import axios from "axios";
import { IUser, UserType } from "./profile.ts";
import { IStore } from "./store.ts";
import { buildQuery, IFilter, IPage } from "@/api/api.ts";

const BASE_URL = import.meta.env.VITE_API_URL;
const API_URL = `${BASE_URL}/users`;

export interface IStoreUser extends UserType {
    stores: IStore[];
    is_superuser: boolean;
}

export interface IUpdateUser extends IUser {}

export const getAllUsers = async (
    page: number = 1,
    size: number = 50,
    filter: IFilter
) => {
    const result = await axios.get<IPage<IStoreUser>>(
        `${API_URL}?page=${page}&size=${size}&${buildQuery(filter)}`,
        {
            headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate",
                Pragma: "no-cache",
                Expires: "0",
            },
        }
    );
    return result.data;
};

export const getSingleUser = async (user_id: string) => {
    const result: { data: IStoreUser } = await axios.get(
        `${API_URL}/${user_id}`
    );
    return result.data;
};

export const updateUser = async (user_id: number, data: IUpdateUser) => {
    const result: { data: IStoreUser } = await axios.put(
        `${API_URL}/${user_id}`,
        data
    );
    return result.data;
};

export const deleteUser = async (user_id: number) => {
    const result: { data: IStoreUser } = await axios.delete(
        `${API_URL}/${user_id}`
    );
    return result.data;
};
