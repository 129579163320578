import { IStoreHours } from "@/api/store.ts";

interface IFormData {
    [key: string]: IStoreHours;
}

export const DAYS_OF_WEEK = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
];
const DAY_TO_INDEX: { [key: string]: number } = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
};

export const getDefaultStoreHours = (
    storeHours: IStoreHours[],
    storeId: string | undefined
) => {
    return DAYS_OF_WEEK.reduce((acc, day) => {
        const dayIndex = DAY_TO_INDEX[day];
        acc[day] = storeHours.find((hours) => hours.day === dayIndex) || {
            id: -1,
            store_id: Number(storeId),
            day: dayIndex,
            open_time: undefined,
            close_time: undefined,
            open: false,
        };
        return acc;
    }, {} as IFormData);
};
