import { useAuth } from "@/components/context/useAuth";
import { Navigate } from "react-router-dom";
import { DriverOrders } from "./components/DriverOrders";

export const DriverOrdersDashboard = () => {
    const { user } = useAuth();

    if (!user?.is_driver && !user?.is_admin) {
        return <Navigate to="/" />;
    }

    return <DriverOrders />;
};

export default DriverOrdersDashboard;
