import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded";
import { Typography } from "@mui/material";
import { ConditionalContainer } from "./ConditionalContainer";

export const PaymentIntentNotFound = () => {
    return (
        <ConditionalContainer>
            <RequestQuoteRoundedIcon
                sx={{ fontSize: 128, color: "#40ABBA", mb: 2 }}
            />
            <Typography variant="h5" fontWeight={600}>
                Payment Intent Not Found
            </Typography>
        </ConditionalContainer>
    );
};
