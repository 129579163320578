import { useAuth } from "@/components/context/useAuth";
import { UserAccounts } from "./components/Accounts";

export const UsersDashboard = () => {
    const { user } = useAuth();

    if (!user?.is_admin) {
        return <div>Unauthorized</div>;
    }

    return <UserAccounts />;
};

export default UsersDashboard;
