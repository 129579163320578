import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { Navigate, useParams } from "react-router-dom";
import { getPaymentIntent } from "@/api/store";
import { PaymentIntentNotFound } from "@/components/dashboard";
import { ConfirmOrder } from "./components/ConfirmOrder";
import { getPaymentIntent as getCustomOrderPaymentIntent } from "@/api/orders.ts";

export const OrderConfirmation = () => {
    const { store_id, payment_intent_id } = useParams();

    const [getStoreOrderPaymentIntentState, getStoreOrderPaymentIntentActions] =
        useAsync(getPaymentIntent);
    const [
        getCustomOrderPaymentIntentState,
        getCustomOrderPaymentIntentActions,
    ] = useAsync(getCustomOrderPaymentIntent);

    useMountEffect(() => {
        if (payment_intent_id) {
            if (store_id) {
                getStoreOrderPaymentIntentActions.execute(
                    store_id,
                    payment_intent_id
                );
            } else {
                getCustomOrderPaymentIntentActions.execute(payment_intent_id);
            }
        }
    });

    const isLoading =
        getStoreOrderPaymentIntentState.status === "loading" ||
        getCustomOrderPaymentIntentState.status === "loading";

    if (isLoading) {
        return (
            <Backdrop
                open={isLoading}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress sx={{ color: "#ffffff" }} />
            </Backdrop>
        );
    }

    const hasError =
        getStoreOrderPaymentIntentState.error ||
        getCustomOrderPaymentIntentState.error;

    if (hasError) {
        return <PaymentIntentNotFound />;
    }

    const storePaymentIntentResult = getStoreOrderPaymentIntentState.result;
    const customPaymentIntentResult = getCustomOrderPaymentIntentState.result;

    if (store_id && !storePaymentIntentResult) {
        return null;
    }

    if (!store_id && !customPaymentIntentResult) {
        return null;
    }

    const paymentIntentResult =
        storePaymentIntentResult || customPaymentIntentResult;

    if (!paymentIntentResult) return null;

    if (paymentIntentResult.status !== "succeeded") {
        return (
            <Navigate
                to={
                    store_id
                        ? `/stores/${store_id}/shipping-form/${storePaymentIntentResult?.id}/checkout`
                        : `/orders/${customPaymentIntentResult?.id}/checkout`
                }
                replace
            />
        );
    }

    return (
        <Box bgcolor="#F3F4F6" minHeight="100vh" overflow="auto">
            <ConfirmOrder paymentIntent={paymentIntentResult} />
        </Box>
    );
};

export default OrderConfirmation;
